import { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { getLocation } from "../../helpers/getLocation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiService from "../../../api/apiService";
import { useAlert } from "../../../context/AlertContext";

let userSchema = object({
  first_name: string().required("First name is required"),
  last_name: string().required("Last name is required"),
  password: string().min(4, "Password must be at least 6 characters"),
});

const EditComment = () => {
  const location = useLocation();
  const { id } = useParams();
  const { setAlertMessage } = useAlert();
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState();
  const [parent, setParent] = useState();
  const commentRef = useRef();
  const isVisible = useRef();

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    password: null,
  });

  const getcomment = async () => {
    try {
      setLoading(true);
      const response = await apiService.getComment(id);
      const data = response.data;
      setComment(data);
      data?.parent && getCommentName(data?.parent);
      console.log("DATA", data?.parent);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getcomment();
  }, []);

  const getCommentName = async (commentID) => {
    try {
      setLoading(true);
      const response = await apiService.getComment(commentID);
      const data = response.data;
      setParent(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const commentData = {
      text: commentRef.current.value,
      visible: isVisible.current.checked,
    };

    try {
      await apiService.updateComment(comment.id, commentData);
      setAlertMessage({
        msg: "The comment has been successfully updated.",
        type: "success",
      });
      navigate("/admin/comments");
    } catch (error) {
      setAlertMessage({
        msg: `An error occurred while updating the comment. ${error}`,
        type: "error",
      });
    }
  };


  return (
    <section className="dashboard__section">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          Blog Management
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>
      <div
        style={{
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
        className="flex flex-col w-full px-6 rounded-lg bg-white"
      >
        <div className="xl:w-10/12 w-full mx-auto py-10">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <div className="w-full flex flex-col">
                <span className="mb-2 inline-block text-neutral-70">User</span>
                <span className="text-gray-500">{comment?.user?.email}</span>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <div className="w-full flex flex-col">
                <span className="mb-2 inline-block text-neutral-70">Blog</span>
                <span className="text-gray-500">{comment?.blog?.title_en}</span>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <div className="w-full flex flex-col">
                <span className="mb-2 inline-block text-neutral-70">
                  Parent User
                </span>
                <span className="text-gray-500">
                  {parent ? parent?.user?.email : "None"}
                </span>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <div className="w-full">
                <label
                  htmlFor="lastname"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Comment
                </label>
                <textarea
                  class="border-solid border-2 text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
                  id="comment"
                  rows="4"
                  ref={commentRef}
                  placeholder="Comment"
                  defaultValue={comment?.text}
                >
                </textarea>
              </div>
            </div>
            <div className="flex mt-5 gap-1">
              <input
                className="h-[1.1rem] w-[1.1rem]"
                type="checkbox"
                id="is_admin"
                defaultChecked={comment?.visible}
                name="is_admin"
                ref={isVisible}
              />
              <label htmlFor="is_admin">Status</label>
            </div>
            <div className="flex flex-row justify-between">
              <button
                type="submit"
                className="mt-5 focus:outline-none rounded-sm text-sm w-full py-2 text-center text-[#fff] bg-[#5ab7ac] hover:bg-[#5ab7acd7]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditComment;
