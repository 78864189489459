const settings = {
  text: {
    number: false,
    specialChar: false,
    minChar: 3,
    maxChar: 25,
  },
  email: {
    number: true,
    specialChar: true,
    minChar: 5,
    maxChar: 50,
  },
  password: {
    number: true,
    specialChar: true,
    minChar: 6,
    maxChar: 30,
  },
  textarea: {
    number: true,
    specialChar: true,
    minChar: 3,
    maxChar: 370,
  },
};

function containsNumbers(str) {
  return /[0-9]/.test(str);
}

function specialChars(str) {
  const regex = /[!@#$%^&*()+=._-]/;
  return regex.test(str);
}

function fullNameCheck(str) {
  const regex = /\w+\s\w+/gm;
  return regex.test(str);
}

// function passwordCheck(str) {
//   // At least 6 characters in length
//   // Minimum of 1 lower-case character
//   // Minimum of 1 upper-case character
//   // Minimum of 1 digit
//   // Minimum of 1 special character (non-whitespace)
//   const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/;
//   return regex.test(str);
// }

function emailCheck(str) {
  const regex = /[\w._-]+@[\w-\d]+\.\w+/gimsy;
  return regex.test(str);
}

// // PASSIVE FUNCTION
// function containsLetters(str) {
//   return /[a-zA-Z]/.test(str);
// }

export const formControl = (formInfo, setAlertMessage, login) => {
  const { from, fullName, email, message, password } = formInfo;

  if (!settings.text.number && containsNumbers(fullName)) {
    setAlertMessage({
      msg: "First name and last name cannot contain numbers!",
      type: "error",
    });
    return;
  } else if (
    fullName?.length < settings.text.minChar ||
    fullName?.length > settings.text.maxChar
  ) {
    setAlertMessage({
      msg: `Full name character length must be greater than ${settings.text.minChar} and less than ${settings.text.maxChar}`,
      type: "error",
    });
    return;
  } else if (fullName && !fullNameCheck(fullName)) {
    setAlertMessage({
      msg: "You should write name and lastname!",
      type: "error",
    });
  } else if (!settings.text.specialChar && specialChars(fullName)) {
    setAlertMessage({
      msg: "Full name cannot contain special characters!",
      type: "error",
    });
  } else if (!emailCheck(email)) {
    setAlertMessage({ msg: "You must write email format!", type: "error" });
  } else if (
    message?.length < settings.textarea.minChar ||
    message?.length > settings.textarea.maxChar
  ) {
    setAlertMessage({
      msg: `Message character length must be greater than ${settings.textarea.minChar} and less than ${settings.textarea.maxChar}!`,
      type: "error",
    });
  } else if (
    (from === "register" && password?.length < settings.password.minChar) ||
    password?.length > settings.password.maxChar
  ) {
    setAlertMessage({
      msg: `Password character length must be greater than ${settings.password.minChar} and less than ${settings.password.maxChar}!`,
      type: "error",
    });
  } else {
    if (from === "contact") {
      setAlertMessage({ msg: "Message sent successfully!", type: "success" });
    } else if (from === "register") {
      setAlertMessage({
        msg: "You have successfully registered!",
        type: "success",
      });
    } else if (from === "login") {
      login(formInfo);
      setAlertMessage({
        msg: "You have successfully logged in!",
        type: "success",
      });
    }
  }
};
