import { useEffect } from "react";

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  });
}

export function useDesc(desc) {
  document.getElementsByTagName("meta")["description"].content = desc;
}

export function useKeywords(keywords) {
  document.getElementsByTagName("meta")["keywords"].content = keywords;
}
