import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Comments from "../../components/Comments";
import { blogList } from "../../utils/data";

const BlogsDetail = () => {
  const { id, url } = useParams();
  const { t, i18n } = useTranslation();

  const blog = blogList[i18n.language.split("-")[0]].find(
    (blog) => blog.id === Number(id)
  );

  // TODO: useSeo use
  const {
    title,
    author,
    date,
    category,
    comments,
    description,
    image,
    imageFilter,
    keywords, // TODO: Use it on pages
    seoUrl,
  } = blog;
  return (
    <>
      <div className="max-w-screen-xl mx-auto mb-8">
        <main className="mt-10">
          <div>
            <div
              className="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative"
              style={{ height: "24em" }}
            >
              <div
                className="absolute left-0 bottom-0 w-full h-full z-10"
                style={{
                  backgroundImage:
                    "linear-gradient(180deg,transparent,rgba(0,0,0,.8))",
                }}
              />
              <img
                src={image}
                alt={title}
                className={`${
                  imageFilter && imageFilter
                } absolute left-0 top-0 w-full h-full z-0 object-cover`}
              />
              <div className="p-4 absolute bottom-0 left-0 z-20">
                <a
                  href="/"
                  className="px-4 py-1 text-yellow-50 bg-yellow-700 inline-flex items-center justify-center mb-2"
                >
                  {category}
                </a>
                <h1 className="text-4xl font-semibold text-yellow-400 leading-tight">
                  {title}
                </h1>
                <div className="flex mt-3">
                  <img
                    src="https://avatars.githubusercontent.com/u/94994862?v=4"
                    className="h-10 w-10 rounded-full mr-2 object-cover"
                    alt={author}
                  />
                  <div>
                    <p className="font-semibold text-gray-200 text-sm">
                      {author}
                    </p>
                    <p className="font-semibold text-gray-400 text-xs">
                      {date}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <article className="px-4 lg:px-0 mt-12 text-gray-300 max-w-screen-md mx-auto text-lg leading-relaxed">
              <p className="pb-6 indent-8">{description}</p>
              {/* <div className="border-l-4 border-gray-200 pl-4 mb-6 rounded indent-4">
                Sportsman do offending supported extremity breakfast by
                listening. Decisively advantages nor expression unpleasing she
                led met. Estate was tended ten boy nearer seemed.
              </div> */}
            </article>
          </div>
        </main>
      </div>
      <Comments comments={comments} t={t} i18n={i18n} />
    </>
  );
};

export default BlogsDetail;
