import { RxCross2 } from "react-icons/rx";

const TagsInput = ({ setAlertMessage, tags, setTags }) => {

  
  const addTags = (e) => {
    const input = e.target;
    const value = input.value.trim();
    if (value.endsWith(",")) {
      const newKeyword = value.slice(0, -1).trim();
      if (
        newKeyword !== "" &&
        !tags.includes(newKeyword) &&
        newKeyword.length >= 3
      ) {
        setTags([...tags, newKeyword]);
        input.value = "";
      } else if (tags.includes(newKeyword)) {
        input.value = value.slice(0, -1);
        setAlertMessage({
          msg: `The tag "${newKeyword}" has already been added.`,
          type: "error",
        });
      } else if (newKeyword.length < 3) {
        input.value = "";
        setAlertMessage({
          msg: `The tag "${newKeyword}" is too short. Please enter a keyword with at least 3 characters.`,
          type: "error",
        });
      }
    }
  };

  const removeTag = (tag) => {
    const newTags = tags.filter((keyword) => keyword !== tag);
    setTags(newTags);
  };

  const clearTags = () => {
    setTags([]);
  };

  return (
    <div className="tags-input mt-4">
      <div className="flex flex-col">
        <label htmlFor="keyword-input" className="text-lg font-medium">
          Keywords:
        </label>
        <div className="flex flex-wrap rounded-md mb-4">
          <input
            id="keyword-input"
            type="text"
            className="border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
            placeholder="Press comma(,) to add tags."
            defaultValue=""
            onChange={addTags}
            inputMode="latin"
          />
        </div>
        <div className="flex flex-row gap-2 flex-wrap">
          {tags.map((tag, i) => (
            <div
              key={i}
              className="flex flex-row gap-2 justify-between items-center bg-slate-400 px-3 py-1 text-slate-200 rounded"
            >
              {tag}
              <RxCross2
                onClick={() => removeTag(tag)}
                className="cursor-pointer"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center">
          <div className="text-sm text-gray-500">Total tags: {tags.length}</div>
          {tags.length > 0 && (
            <button
              onClick={clearTags}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded"
            >
              Clear tags
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagsInput;
