import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/logo-no-text.png";
import { SiBloglovin } from "react-icons/si";
import { BiCategory } from "react-icons/bi";
import { FaUsers, FaComments } from "react-icons/fa";
import { RiSettings3Fill } from "react-icons/ri";
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useState } from "react";

import { useLocation } from "react-router-dom";
import { url } from "../../utils/url";

const Aside = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="dashboard__aside__container">
      <button className={`menu-toggle`} onClick={() => setIsOpen(!isOpen)}>
        <FaBars className="text-xl" />
      </button>
      <aside className={`dashboard__aside ${isOpen ? "open" : ""}`}>
        <button className={`menu-cross`} onClick={() => setIsOpen(!isOpen)}>
          <RxCross2 className="text-xl" />
        </button>
        <div className="dashboard__logo">
          <Link to={url("admin")}>
            <img src={logo} alt="Logo" className="select-none" />
          </Link>
        </div>
        <nav className="dashboard__nav select-none">
          <ul>
            <li>
              <NavLink
                to={url("admin.blogManagement")}
                className={`${
                  String(location.pathname.split("/").slice(-1)) === "admin" &&
                  "active"
                } `}
              >
                <div className="flex flex-row gap-1">
                  <SiBloglovin className="md:text-xl text-lg" />
                  Blog Management
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={url("admin.categories")}>
                <div className="flex flex-row gap-1">
                  <BiCategory className="md:text-xl text-lg" />
                  Categories
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={url("admin.users")}>
                <div className="flex flex-row gap-2">
                  <FaUsers className="text-xl" />
                  Users
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={url("admin.comments")}>
                <div className="flex flex-row gap-2">
                  <FaComments className="md:text-xl text-lg" />
                  Comments
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={url("admin.settings")}>
                <div className="flex flex-row gap-2">
                  <RiSettings3Fill className="md:text-xl text-lg" />
                  Settings
                </div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default Aside;
