import { Link, useNavigate } from "react-router-dom";
import { url } from "../../utils/url";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";

const Header = () => {
  const { user } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    const dropdownMenu = document.getElementById("dropdownMenu");
    dropdownMenu.classList.toggle("hidden");
  };

  const logoutHandle = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Logout failed: ", error);
    }
  };
  return (
    <header className="dashboard__header">
      <div className="header__right-side">
        <div className="flex space-x-2 justify-center">
          <div className="dropdown relative">
            <span
              className="
                dropdown-toggle
                px-6
                py-2.5
                text-xs
                leading-tight
                uppercase
                rounded
                cursor-pointer
                transition
                duration-150
                ease-in-out
                flex
                items-center
                whitespace-nowrap
                select-none
              "
              type="button"
              onClick={toggleDropdown}
              id="dropdownMenuButton9"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-down"
                className="w-2 mr-2"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                />
              </svg>
              {user.first_name}
            </span>
            <ul
              id="dropdownMenu"
              className="
                dropdown-menu
                min-w-max
                absolute
                hidden
                bg-white
                text-base
                z-50
                float-left
                py-2
                list-none
                text-left
                rounded-lg
                shadow-lg
                mt-1
                m-0
                bg-clip-padding
                border-none
                right-0 
              "
              aria-labelledby="dropdownMenuButton9"
            >
              <li>
                <Link
                  className="
                    dropdown-item
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    bg-white
                    hover:bg-gray-100
                    first-letter:
                  "
                  to={url("admin")}
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  className="
                    dropdown-item
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                  to={url("main")}
                >
                  Preview Web Site
                </Link>
              </li>
              <li>
                <Link
                  className="
                    dropdown-item
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                    first-letter:
                  "
                  onClick={logoutHandle}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
