import { useAlert } from "../context/AlertContext";

const Alert = () => {
  const { alertMessage, alertClass } = useAlert();

  return (
    <div className="alert-container">
      <div className={`alert-msg ${alertClass} ${alertMessage.type}`}>
        {alertMessage.msg}
      </div>
    </div>
  );
};

export default Alert;
