import { useRef, useState } from "react";
import GoogleIcon from "../assets/GoogleIcon";
import RegisterPic from "../assets/register.svg";
import { useAlert } from "../context/AlertContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDesc, useTitle, useKeywords } from "../helpers/useSeo";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../api/apiService";
import { object, string, ref } from "yup";
import { handleValidationAndBackendErrors } from "../utils/validation";

let userSchema = object({
  first_name: string().required("First name is required"),
  last_name: string().required("Last name is required"),
  email: string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: string()
    .min(6, "Password must be at least 6 characters")
    .oneOf([ref("password2")], "Passwords must match")
    .required("Password is required"),
  password2: string()
    .min(6, "Password must be at least 6 characters") //TODO: DJANGOYA GORE AYARLA
    .oneOf([ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});

const Register = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language.split("-")[0];

  useTitle(t("Register useTitle"));
  useDesc(t("Register useDesc"));
  useKeywords(t("Register useKeywords"));

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    password2: null,
  });

  const { setAlertMessage } = useAlert();

  const location = useLocation();
  const navigate = useNavigate();

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const password2Ref = useRef();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() ;
  }

  const handleInputChange = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    setLoading(false);
  };

  async function onSubmitForm(event) {
    event.preventDefault();

    const data = {
      first_name: capitalizeFirstLetter(firstNameRef.current.value),
      last_name: capitalizeFirstLetter(lastNameRef.current.value),
      email: emailRef.current.value.toLowerCase(),
      password: passwordRef.current.value,
      password2: password2Ref.current.value,
    };

    setLoading(true);
    try {
      const response = await handleValidationAndBackendErrors(
        data,
        userSchema,
        setErrors,
        axiosInstance.post.bind(null, "auth/register", JSON.stringify(data))
      );
      if (response?.validationError) {
        return;
      }

      setLoading(false);

      navigate(`/${language}/login`);

      if (response) {
        setAlertMessage({
          msg: "You have successfully registered.",
          type: "success",
        });
      }
    } catch (error) {
      const errorData = error?.response?.data;
      if (errorData) {
        const newErrors = { ...errors }; // mevcut hataları kopyalayın
        for (const field in errorData) {
          if (errorData.hasOwnProperty(field)) {
            // Alanla ilgili hataları ayıklayın ve onları alanın hatalarına ekleyin
            newErrors[field] = errorData[field].join(", ");
          }
        }
        setErrors(newErrors);
      } else {
        setAlertMessage({
          msg: `An error occurred during the registration process: ${
            error.message || "Unexpected error"
          }`,
          type: "error",
        });
      }
      setLoading(false);
    }
  }

  return (
    <div className="login flex justify-center dark:bg-[#23242a]">
      <div className="lg:w-7/12 xl:w-8/12 form-image hidden lg:block">
        <img
          src={RegisterPic}
          alt="sample-movie"
          className="object-fit w-full ml-6"
        />
      </div>
      <div className="forms flex flex-1 w-2/12 justify-center items-center">
        <div className="box register">
          <form className="form" onSubmit={onSubmitForm}>
            <h2>{t("Sign up")}</h2>
            <div className="relative w-[300px] mt-[25px] inputbox flex gap-3">
              <div className="relative inputBox w-[145px] mr-auto inputbox">
                <input
                  className="capitalize relative w-[100%] inputbox-input bg-transparent outline-none text-[#23242a] font-[1em] tracking-[0.05em]"
                  type="text"
                  name="firstName"
                  ref={firstNameRef}
                  onChange={() => handleInputChange("first_name")}
                  required
                />
                <span className="absolute left-0 inputbox-span font-[1em] text-[#8f8f8f] tracking-[0.05em]">
                  {t("First Name")}
                </span>
                <i
                  className={`absolute right-0 bottom-0 w-[100%] h-[2px] bg-[#FFC538] rounded-[4px] ${
                    errors.first_name ? "error" : ""
                  }`}
                ></i>
                {errors.first_name && (
                  <p className="error-message">{errors.first_name}</p>
                )}
              </div>

              <div className="relative inputBox w-[145px] ml-auto inputbox ">
                <input
                  className="capitalize relative w-[100%] inputbox-input bg-transparent outline-none text-[#23242a] font-[1em] tracking-[0.05em]"
                  type="text"
                  name="lastName"
                  ref={lastNameRef}
                  onChange={() => handleInputChange("last_name")}
                  required
                />
                <span className="absolute right-0 inputbox-span font-[1em] text-[#8f8f8f] tracking-[0.05em]">
                  {t("Last Name")}
                </span>
                <i
                  className={`absolute right-0 bottom-0 w-[100%] h-[2px] bg-[#FFC538] rounded-[4px] ${
                    errors.last_name ? "error" : ""
                  }`}
                ></i>
                {errors.last_name && (
                  <p className="error-message">{errors.last_name}</p>
                )}
              </div>
            </div>
            <div className="inputBox mt-7">
              <input
                type="email"
                name="email"
                ref={emailRef}
                onChange={() => handleInputChange("email")}
                required
              />
              <span>{t("Email")}</span>
              <i className={errors.email ? "error" : ""} />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="inputBox mt-7">
              <input
                type="password"
                name="password"
                ref={passwordRef}
                onChange={() => handleInputChange("password")}
                required
              />
              <span>{t("Password")}</span>
              <i className={errors.password ? "error" : ""} />
              {errors.password && (
                <p className="error-message">{errors.password}</p>
              )}
            </div>
            <div className="inputBox mt-7">
              <input
                type="password"
                name="password2"
                ref={password2Ref}
                onChange={() => handleInputChange("password2")}
                required
              />
              <span>{t("Confirm Password")}</span>
              <i className={errors.password2 ? "error" : ""} />
              {errors.password2 && (
                <p className="error-message">{errors.password2}</p>
              )}
            </div>
            <div className="flex justify-between mt-5">
                <Link
                  className="text-xs cursor-pointer decoration-none text-[#8f8f8f]"
                  to={`/${language}/login`}
                  state={{
                    return_url: location.pathname,
                  }}
                >
                  {t("Login")}
                </Link>
              </div>
            <input
              disabled={loading}
              className="btns mt-5"
              type="submit"
              value={t("Register")}
            />
            {/* <button className="btns mt-5" type="button">
              {t("Continue with Google")}
              <GoogleIcon color="currentColor" />
            </button> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
