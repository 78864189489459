import { Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LangLayout = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  // console.log(i18n.language.split("-")[0]);
  // console.log(localStorage.getItem("i18nextLng"));

  return (
    <Navigate
      to={i18n.language.split("-")[0]}
      replace
      state={{
        return_url: location.pathname,
      }}
    />
  );
};

export default LangLayout;
