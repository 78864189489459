import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { TbBrandGithub } from "react-icons/tb";
import { FaLinkedinIn } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";

const Social = () => {
  const [isOpen, setIsOPen] = useState(false);
  return (
    <div className={`fab z-50 ${isOpen ? "open" : ""}`}>
      <button className="btn plus" onClick={() => setIsOPen(!isOpen)}>
        <BsPlusLg />
      </button>
      <div className="menu">
        <a
          className="btn"
          href="https://github.com/sinansarikaya"
          target="_blank"
          rel="noreferrer"
        >
          <TbBrandGithub className="btn" />
        </a>
        <a
          className="btn"
          href="https://www.linkedin.com/in/sinansarykaya/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn />
        </a>
        <a
          className="btn"
          href="https://www.instagram.com/sinan.sarykaya/"
          target="_blank"
          rel="noreferrer"
        >
          <FiInstagram className="btn" />
        </a>
      </div>
    </div>
  );
};

export default Social;
