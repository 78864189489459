import React from "react";
import { Outlet } from "react-router-dom";

const CommentLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default CommentLayout;
