import { AiTwotoneEdit, AiFillDelete } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getLocation } from "../helpers/getLocation";
import { useAlert } from "../../context/AlertContext";
import apiService from "../../api/apiService";
import CategoryModal from "../components/CategoryModal";

const Categories = () => {
  const location = useLocation();
  const { setAlertMessage } = useAlert();
  const [categories, setCategories] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const openEditModal = (category) => {
    setSelectedCategory(category);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
  };

  const updateCategory = (updatedName) => {
    apiService
      .updateCategory(selectedCategory.id, { name: updatedName })
      .then(() => {
        getCategories();
        setAlertMessage({
          msg: "The category has been successfully updated.",
          type: "success",
        });
        closeEditModal();
      })
      .catch((error) => {
        setAlertMessage({
          msg: `An error occurred while updating the category. ${error}`,
          type: "error",
        });
      });
  };

  const getCategories = () => {
    apiService
      .getCategories()
      .then((response) => {
        const data = response.data;
        setCategories(data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const deleteCategory = (id) => {
    apiService
      .deleteCategory(id)
      .then(() => {
        setAlertMessage({
          msg: "The category has been successfully deleted.",
          type: "warning",
        });
        getCategories();
      })
      .catch((error) => {
        setAlertMessage({
          msg: `An error occurred while deleting the category. ${error}`,
          type: "error",
        });
        console.error(error);
      });
  };

  const createCategory = (e) => {
    e.preventDefault();

    const categoryName = e.target.elements.categoryName.value;

    apiService
      .createCategory({ name: categoryName })
      .then(() => {
        getCategories();
        setAlertMessage({
          msg: "The category has been successfully added.",
          type: "success",
        });
        e.target.elements.categoryName.value = "";
      })
      .catch((error) => {
        setAlertMessage({
          msg: `An error occurred while adding the category. ${error}`,
          type: "error",
        });
      });
  };

  return (
    <section className="dashboard__section flex flex-col">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          Category List
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>
      <div className="flex flex-wrap-reverse xl:flex-nowrap gap-9 w-full">
        <div
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
          className="xl:w-9/12 w-full bg-white py-10 px-6 rounded-md ml-auto"
        >
          <table className="w-full border text-sm text-left">
            <thead className="text-xs uppercase bg-[#5ab7ac] text-[#fff]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Category name
                </th>

                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {categories.map(({ id, name }) => (
                <tr
                  key={id}
                  className="text-gray-600 border-b hover:bg-[#5ab7ac30]"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {name}
                  </th>
                  <td className="px-6 py-4 flex gap-5 text-lg">
                    <span
                      onClick={() => openEditModal({ id, name })}
                      className="font-medium text-[#5ab7acb6] hover:text-[#5ab7ac57] transition ease-in-out duration-500 cursor-pointer"
                    >
                      <AiTwotoneEdit />
                    </span>
                    <span
                      onClick={() => deleteCategory(id)}
                      className="font-medium text-red-700 hover:text-red-500 transition ease-in-out duration-500 cursor-pointer"
                    >
                      <AiFillDelete />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
          className="xl:w-3/12 w-full bg-white py-10 px-6 rounded-md ml-auto"
        >
          <form className="group relative mb-8" onSubmit={createCategory}>
            <h2>Add New Category</h2>
            <hr />
            <div className="mb-5 mt-8">
              <label htmlFor="categoryName" className="sr-only">
                Category Name
              </label>
              <input
                type="text"
                id="categoryName"
                name="categoryName"
                className="focus:outline-none w-full bg-slate-50 text-slate-800 placeholder-slate-400 rounded-sm p-3 py-3 text-sm shadow-md"
                placeholder="Category Name"
                required
              />
            </div>

            <button
              type="submit"
              className="focus:outline-none rounded-sm text-sm w-full py-2 text-center text-[#fff] bg-[#5ab7ac] hover:bg-[#5ab7acd7]"
            >
              Add
            </button>
          </form>
        </div>
        {/* Modal for Edit */}
        {showEditModal && (
          <CategoryModal
            mode="edit"
            category={selectedCategory}
            onClose={closeEditModal}
            onUpdate={updateCategory}
          />
        )}
      </div>
    </section>
  );
};

export default Categories;
