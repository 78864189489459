import { Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";

export default function PrivateRouter({ children }) {
  const { user } = useAuth();
  const location = useLocation();
  const { i18n } = useTranslation();
  if (!user) {
    return (
      <Navigate
        to={`/${i18n.language.split("-")[0]}/login`}
        replace
        state={{
          return_url: location.pathname,
        }}
      />
    );
  }
  return children;
}
