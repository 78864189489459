import { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GoogleIcon from "../assets/GoogleIcon";
import LoginPic from "../assets/login.jpg";
import ForgotModal from "../components/ForgotModal";
import { useAlert } from "../context/AlertContext";
import { useDesc, useTitle, useKeywords } from "../helpers/useSeo";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import { axiosInstance } from "../api/apiService";
import { object, string } from "yup";
import { handleValidationAndBackendErrors } from "../utils/validation";

let userSchema = object({
  email: string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: string()
    .min(4, "Password must be at least 6 characters") //TODO: Minimum karakter sayisi ayari
    .required("Password is required"),
});


const Login = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language.split("-")[0];

  useTitle(t("Login useTitle"));
  useDesc(t("Login useDesc"));
  useKeywords(t("Login useKeywords"));

  const { setAccessToken, setCSRFToken, setIsLoggedIn } = useAuth();
  const { setAlertMessage } = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();
  const [forgotModal, setForgotModal] = useState(false);

  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });

  async function onSubmitForm(event) {
    event.preventDefault();

    const data = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    setLoading(true);
    try {
      const response = await handleValidationAndBackendErrors(
        data,
        userSchema,
        setErrors,
        axiosInstance.post.bind(null, "auth/login", data)
      );
      if (response?.validationError) {
        return;
      }

      setAccessToken(response?.data?.access_token);
      setCSRFToken(response.headers["x-csrftoken"]);
      setIsLoggedIn(true);
      setLoading(false);

      navigate(location?.state?.return_url || "/");

      if (response) {
        setAlertMessage({
          msg: "You have successfully logged in.",
          type: "success",
        });
      }
    } catch (error) {
      if (error?.response?.data?.non_field_errors) {
          setAlertMessage({
              msg: error.response.data.non_field_errors[0],
              type: "error",
          });
      } else {
          setAlertMessage({
              msg: `An error occurred during the login process: ${
                error.message || "Unexpected error"
              }`,
              type: "error",
          });
      }
    } finally {
      setLoading(false);
    }
  }

  const handleForgot = () => {
    setForgotModal(true);
  };

  return (
    <>
      <div className="login flex justify-center dark:bg-[#23242a]">
        <div className="lg:w-7/12 xl:w-8/12 form-image hidden lg:block">
          <img
            src={LoginPic}
            alt="sample-movie"
            className="object-cover w-full"
          />
        </div>
        <div className="flex flex-1 w-2/12 justify-center items-center">
          <div className="box">
          <form className="form" onSubmit={event => onSubmitForm(event).catch(console.error)}>
              <h2>{t("Sign in")}</h2>
              <div className="inputBox">
                <input type="email" name="email" ref={emailRef} required />
                <span>{t("Email")}</span>
                <i className={errors.email ? "error" : ""} />
                {errors.email && (
                  <p className="error-message">{errors.email}</p>
                )}
              </div>
              <div className="inputBox mt-7">
                <input
                  type="password"
                  name="password"
                  ref={passwordRef}
                  required
                />
                <span>{t("Password")}</span>
                <i className={errors.password ? "error" : ""} />
                {errors.password && (
                  <p className="error-message">{errors.password}</p>
                )}
              </div>
              <div className="flex justify-between mt-5">
                <span
                  onClick={handleForgot}
                  role="button"
                  className="text-xs cursor-pointer decoration-none text-[#8f8f8f]"
                >
                  {t("Forgot Password")}
                </span>
                <Link
                  className="text-xs cursor-pointer decoration-none text-[#8f8f8f]"
                  to={`/${language}/register`}
                  state={{
                    return_url: location.pathname,
                  }}
                >
                  {t("Sign up")}
                </Link>
              </div>
              <input
                disabled={loading}
                className="btns mt-5"
                type="submit"
                value={t("Login")}
              />
              {/* <button className="btns mt-5" type="button">
                {t("Continue with Google ")}
                <GoogleIcon color="currentColor" />
              </button> */}
            </form>
          </div>
        </div>
      </div>

      {
        <ForgotModal
          setForgotModal={setForgotModal}
          forgotModal={forgotModal}
        />
      }
    </>
  );
};

export default Login;
