import { useState } from "react";

const CategoryModal = ({
  mode = "create",
  category = { name: "" },
  onClose,
  onUpdate,
  onCreate,
}) => {
  const [categoryName, setCategoryName] = useState(category.name);
  const isEditMode = mode === "edit";

  const handleSubmit = (e) => {
    e.preventDefault();
    isEditMode ? onUpdate(categoryName) : onCreate(e);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white py-8 px-10 rounded-md">
        <h2 className="text-xl font-bold mb-4">
          {isEditMode ? "Edit Category" : "Create Category"}
        </h2>
        <form onSubmit={handleSubmit}>
          <input
            name="categoryName"
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="bg-gray-100 rounded-md py-2 px-4 mb-4"
          />
          <button
            type="submit"
            className="bg-[#5ab7ac] text-white py-2 px-4 rounded-md ml-2"
          >
            {isEditMode ? "Update" : "Create"}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-red-500 text-white py-2 px-4 rounded-md ml-2"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default CategoryModal;
