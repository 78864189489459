import React from "react";
import Logo from "../assets/logo-no-text.png";

const Footer = () => {
  return (
    <div className="footer-bg mt-6">
      <svg
        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-deep-purple-accent-400"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="#252422"
          d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
        />
      </svg>

      <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="w-fit flex flex-col items-center">
          <div className="w-fit items-center grid ">
            <div className="flex flex-col items-center w-fit lg:col-span-4">
              <img
                src={Logo}
                style={{ height: "45px" }}
                alt="Logo"
                loading="lazy"
              />
              <div className="mt-3 lg:max-w-sm">
                <p className="text-sm footer-description">Sinan Sarıkaya</p>
              </div>
            </div>
          </div>
          <div className="copyright flex flex-col justify-between pt-3 pb-10 sm:flex-row">
            <p className="text-sm">
              © Copyright {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
