import EditorTiny from "./EditorTiny";
import TagsInput from "./TagsInput";

const BlogForm = ({
  titleRef,
  setTinyContent,
  setAlertMessage,
  tags,
  setTags,
  tinyId,
  defaultTitle,
  defaultContent
}) => {
  return (
    <>
      <div className="flex flex-col justify-center mt-4">
        <label htmlFor="title" className="mb-2 inline-block text-neutral-70">
          Title
        </label>
        <input
          type="text"
          className="border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
          placeholder="Title"
          ref={titleRef}
          id="title"
          name="title"
          defaultValue={defaultTitle}
        />
      </div>
      <div className="flex flex-col justify-center mt-4">
        <label htmlFor={tinyId} className="mb-2 inline-block text-neutral-70">
          Content
        </label>
        <EditorTiny tinyContent={defaultContent} setTinyContent={setTinyContent} tinyId={tinyId} />
      </div>
      <div className="flex flex-col justify-center">
        <TagsInput
          setAlertMessage={setAlertMessage}
          tags={tags}
          setTags={setTags}
        />
      </div>
    </>
  );
};

export default BlogForm;
