import { IoMdNotifications } from "react-icons/io";
import { FaUnlockAlt, FaFeatherAlt } from "react-icons/fa";
import { AiOutlineLink } from "react-icons/ai";
import Logo from "../assets/logo-no-text.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { url } from "../utils/url";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import useUser from "../hooks/useUser";
import { Dropdown, Collapse, initTE } from "tw-elements";

const Navbar = () => {
  const { user, isLoggedIn } = useAuth();
  const logout = useLogout();
  const getUser = useUser();
  initTE({ Dropdown, Collapse });

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeHandle = (e) => {
    i18n.changeLanguage(e.target.value);
    navigate(
      `${e.target.value}/${location.pathname.split("/").slice(2).join("/")}`
    );
  };

  useEffect(() => {
    getUser();
  }, [isLoggedIn]);

  async function onLogout() {
    await logout();
    navigate("/");
  }

  // if (!user?.is_staff) {
  //   navigate("en/login");
  // }

  return (
    <nav
      className="relative flex w-full flex-wrap items-center justify-between bg-neutral-900 py-2 text-neutral-200 shadow-lg lg:flex-wrap lg:justify-start lg:py-4"
      data-te-navbar-ref=""
    >
      <div className="flex w-full flex-wrap items-center justify-between px-3">
        {/* Hamburger button for mobile view */}
        <button
          className="block border-0 bg-transparent px-2 text-neutral-200 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
          type="button"
          data-te-collapse-init=""
          data-te-target="#navbarSupportedContent4"
          aria-controls="navbarSupportedContent4"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {/* Hamburger icon */}
          <span className="[&>svg]:w-7">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-7 w-7"
            >
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {/* Collapsible navigation container */}
        <div
          className="!visible mt-2 hidden flex-grow basis-[100%] items-center lg:mt-0 lg:!flex lg:basis-auto"
          id="navbarSupportedContent4"
          data-te-collapse-item=""
        >
          {/* Navbar logo */}
          <NavLink
            className="logo flex items-center my-auto justify-center"
            to="/"
          >
            <img
              src={Logo}
              style={{ height: "45px" }}
              alt="Logo"
              loading="lazy"
            />
          </NavLink>
          {/* Centered navigation links */}
          <ul
            className="list-style-none flex items-center my-auto justify-center mx-auto flex flex-col pl-0 lg:flex-row"
            data-te-navbar-nav-ref=""
          >
            <NavLink
              className="nav-link p-2"
              to={url(`main.${i18n.language.split("-")[0]}.about`)}
            >
              <li className="nav-item p-0">{t("About Me")}</li>
            </NavLink>
            <NavLink
              className="nav-link p-2"
              to={url(`main.${i18n.language.split("-")[0]}.blogs`)}
            >
              <li className="nav-item p-0">{t("Blogs")}</li>
            </NavLink>
            <NavLink
              className="nav-link p-2"
              to={url(`main.${i18n.language.split("-")[0]}.contact`)}
            >
              <li className="nav-item p-0">{t("Contact")}</li>
            </NavLink>
            {isLoggedIn && (user?.is_staff || user?.is_admin) && (
              <NavLink className="nav-link p-2" to={url(`admin`)}>
                <li className="nav-item p-0">{t("Admin")}</li>
              </NavLink>
            )}
            <a
              className="nav-link p-2"
              href="https://github.com/sinansarikaya"
              target="_blank"
              rel="noreferrer"
            >
              <li className="nav-item p-0 flex gap-1">
                GitHub <AiOutlineLink />
              </li>
            </a>
          </ul>
        </div>
        {/* Right elements */}
        <div className="relative flex items-center">
          <div className="flex gap-3 right-side">
            {isLoggedIn ? (
              <>
                <div data-count="6" className="notifications">
                  <IoMdNotifications className="notification right-item" />
                </div>
                <NavLink
                  to={url("main.profile.user", { id: 1 })}
                  className="right-item "
                >
                  {user?.first_name} {user?.last_name}
                </NavLink>

                <button className="right-item mr-4" onClick={onLogout}>
                  {t("Logout")}
                </button>
              </>
            ) : (
              <>
                <NavLink
                  className="mr-4"
                  to={`${i18n.language.split("-")[0]}/login`}
                  state={{
                    return_url: location.pathname,
                  }}
                >
                  <FaUnlockAlt />
                </NavLink>
                <div className="dropdown relative">
                  <NavLink
                    className="mr-4 dropdown-toggle hidden-arrow flex items-center"
                    to={`${i18n.language.split("-")[0]}/register`}
                    state={{
                      return_url: location.pathname,
                    }}
                  >
                    <FaFeatherAlt />
                  </NavLink>
                </div>
              </>
            )}
            <select
              className="language text-yellow-50 outline-none"
              onChange={changeHandle}
              value={i18n.language.split("-")[0]}
            >
              <option value="en">English</option>
              <option value="no">Norwegian</option>
              <option value="tr">Turkish</option>
            </select>
          </div>
        </div>
        {/* Right elements */}
      </div>
    </nav>
  );
};

export default Navbar;
