import { useLocation } from "react-router-dom";
import { getLocation } from "../helpers/getLocation";

const Settings = () => {
  const location = useLocation();
  return (
    <section className="dashboard__section">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          Settings
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>
    </section>
  );
};

export default Settings;
