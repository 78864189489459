import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocation } from "../../helpers/getLocation";
import apiService from "../../../api/apiService";
import { useAlert } from "../../../context/AlertContext";

const AddUser = () => {
  const location = useLocation();
  const { setAlertMessage } = useAlert();
  const [showPassword, setShowPassword] = useState(false);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const isAdminRef = useRef();
  const isStaffRef = useRef();

  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() ;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      first_name: capitalizeFirstLetter(firstNameRef.current.value),
      last_name: capitalizeFirstLetter(lastNameRef.current.value),
      password: passwordRef.current.value,
      email: emailRef.current.value,
      is_admin: isAdminRef.current.checked,
      is_staff: isStaffRef.current.checked,
    };

    try {
      await apiService.createUser(userData);
      setAlertMessage({
        msg: "The user has been successfully added.",
        type: "success",
      });

      navigate("/admin/users");
    } catch (error) {
      setAlertMessage({
        msg: `An error occurred while adding the user. ${error}`,
        type: "error",
      });
    }
  };

  return (
    <section className="dashboard__section">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          Add a new user
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>

      <div
        style={{
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
        className="flex flex-col w-full px-6 rounded-lg bg-white"
      >
        <div className="xl:w-10/12 w-full mx-auto py-10">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <div className="w-full">
                <label
                  htmlFor="firstname"
                  className="mb-2 inline-block text-neutral-70"
                >
                  First Name
                </label>
                <input
                  id="firstname"
                  type="text"
                  className="capitalize border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
                  placeholder="First Name"
                  name="first_name"
                  ref={firstNameRef}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mt-5 w-full">
                <label
                  htmlFor="lastname"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Last Name
                </label>
                <input
                  id="lastname"
                  type="text"
                  className="capitalize border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
                  placeholder="Last Name"
                  name="last_name"
                  ref={lastNameRef}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mt-5 w-full">
                <label
                  htmlFor="email"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className="border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
                  placeholder="Email"
                  name="email"
                  ref={emailRef}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mt-5 w-full relative">
                <label
                  htmlFor="password"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Password
                </label>
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  className="border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
                  placeholder="Password"
                  name="password"
                  ref={passwordRef}
                />

                <button
                  type="button"
                  className="absolute top-11 right-2 transform"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
            <div className="flex mt-5 gap-1">
              <input
                className="h-[1.1rem] w-[1.1rem]"
                type="checkbox"
                id="is_admin"
                name="is_admin"
                ref={isAdminRef}
              />
              <label htmlFor="is_admin">Admin</label>
            </div>
            <div className="flex mt-5 gap-1">
              <input
                className="h-[1.1rem] w-[1.1rem]"
                type="checkbox"
                id="is_staff"
                name="is_staff"
                ref={isStaffRef}
              />
              <label htmlFor="is_staff">Staff</label>
            </div>

            <div className="flex flex-row justify-between">
              <button
                type="submit"
                className="mt-5 focus:outline-none rounded-sm text-sm w-full py-2 text-center text-[#fff] bg-[#5ab7ac] hover:bg-[#5ab7acd7]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AddUser;
