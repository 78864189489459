const Search = ({ t }) => {
  return (
    <section className="shadow-lg flex py-10 dark:text-gray-100 mt-5">
      <div className="flex flex-col items-center w-full justify-end gap-8 text-center group ">
        {/* <h1 className="mb-auto text-xl font-semibold text-gray-200">
          Type the name of the blog you want to read.
        </h1> */}
        <form className="w-full justify-center items-center flex-col md:flex-row flex gap-4">
          <input
            className="focus:ring-2 focus:ring-yellow-400 focus:outline-none appearance-none w-8/12 text-sm leading-6 text-slate-300 placeholder-slate-400 rounded-md py-2 pl-10 ring-1 ring-slate-200 shadow-sm"
            type="text"
            placeholder={t("Search Placeholder")}
          />
          <input
            type="submit"
            className="btns md:w-2/12 w-8/12"
            value={t("Search")}
          />
        </form>
      </div>
    </section>
  );
};

export default Search;
