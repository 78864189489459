import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "tw-elements";
import { BrowserRouter } from "react-router-dom";
import { AlertProvider } from "./context/AlertContext";
import { AuthContextProvider } from "./context/AuthContext";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AlertProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </AlertProvider>
  </BrowserRouter>
);
