// Admin Pages
import BlogManagement from "../admin/pages/blogManagement/BlogManagement";
import Categories from "../admin/pages/Categories";
import Settings from "../admin/pages/Settings";
import UserLayout from "../admin/pages/userManagement/UserLayout";
import Users from "../admin/pages/userManagement/Users";
import AdminBlogLayout from "../admin/pages/blogManagement/AdminBlogLayout";
import AdminBlogDetail from "../admin/pages/blogManagement/AdminBlogDetail";
import AddBlog from "../admin/pages/blogManagement/AddBlog";
import AddUser from "../admin/pages/userManagement/AddUser";
import EditUser from "../admin/pages/userManagement/EditUser";
// import AdminLogin from "../admin/pages/AdminLogin";
import AdminPrivateRouter from "./AdminPrivateRouter";
import AdminPersistLogin from "../admin/components/AdminPersistLogin";
import CommentList from "../admin/pages/commentManagement/CommentList";
import CommentLayout from "../admin/pages/commentManagement/CommentLayout";

// Main Pages
import Blogs from "../pages/blogs/Blogs";
import About from "../pages/About";
import BlogsDetail from "../pages/blogs/BlogsDetail";
import Contact from "../pages/Contact";
import Login from "../pages/Login";
import Register from "../pages/Register";
import NotFound from "../pages/NotFound";
import BlogLayout from "../pages/blogs/BlogLayout";
import Profile from "../pages/profile/Profile";
import ProfileLayout from "../pages/profile/ProfileLayout";
import { Navigate } from "react-router-dom";


import LangLayout from "../pages/layouts/LangLayout";
import EnLayout from "../pages/layouts/EnLayout";
import NoLayout from "../pages/layouts/NoLayout";
import TrLayout from "../pages/layouts/TrLayout";
import PrivateRouter from "./PrivateRouter";
import PersistLogin from "../components/PersistLogin";
import EditComment from "../admin/pages/commentManagement/EditComment";


const routes = [
  {
    name: "main",
    path: "/",
    element: <PersistLogin />,
    children: [
      {
        name: "index",
        index: true,
        element: <LangLayout />,
      },
      {
        name: "en",
        path: "en",
        element: <EnLayout />,
        children: [
          {
            name: "index",
            index: true,
            element: <Navigate to="/en/about" replace={true} />, // TODO: Import user id here.
          },
          {
            name: "about",
            path: "about",
            element: <About />,
          },
          {
            name: "blogs",
            path: "blogs",
            element: <BlogLayout />,
            children: [
              {
                name: "index",
                index: true,
                element: <Blogs />,
              },
              {
                name: "post",
                path: "post/:id/:url",
                element: <BlogsDetail />,
              },
            ],
          },
          {
            name: "contact",
            path: "contact",
            element: <Contact />,
          },
          {
            name: "login",
            path: "login",
            element: <Login />,
          },
          {
            name: "register",
            path: "register",
            element: <Register />,
          },
          {
            name: "profile",
            path: "profile",
            element: <ProfileLayout />,
            auth: true,
            children: [
              {
                name: "index",
                index: true,
                element: <Navigate to="/" replace={true} />, // TODO: Import user id here.
              },
              {
                name: "user",
                path: "user/:id",
                element: <Profile />,
              },
            ],
          },
        ],
      },
      {
        name: "tr",
        path: "tr",
        element: <TrLayout />,
        children: [
          {
            name: "index",
            index: true,
            element: <Navigate to="/tr/about" replace={true} />, // TODO: Import user id here.
          },
          {
            name: "about",
            path: "about",
            element: <About />,
          },
          {
            name: "blogs",
            path: "blogs",
            element: <BlogLayout />,
            children: [
              {
                name: "index",
                index: true,
                element: <Blogs />,
              },
              {
                name: "post",
                path: "post/:id/:url",
                element: <BlogsDetail />,
              },
            ],
          },
          {
            name: "contact",
            path: "contact",
            element: <Contact />,
          },
          {
            name: "login",
            path: "login",
            element: <Login />,
          },
          {
            name: "register",
            path: "register",
            element: <Register />,
          },
          {
            name: "profile",
            path: "profile",
            element: <ProfileLayout />,
            auth: true,
            children: [
              {
                name: "index",
                index: true,
                element: <Navigate to="/" replace={true} />, // TODO: Import user id here.
              },
              {
                name: "user",
                path: "user/:id",
                element: <Profile />,
              },
            ],
          },
        ],
      },
      {
        name: "no",
        path: "no",
        element: <NoLayout />,
        children: [
          {
            name: "index",
            index: true,
            element: <Navigate to="/no/about" replace={true} />, // TODO: Import user id here.
          },
          {
            name: "about",
            path: "about",
            element: <About />,
          },
          {
            name: "blogs",
            path: "blogs",
            element: <BlogLayout />,
            children: [
              {
                name: "index",
                index: true,
                element: <Blogs />,
              },
              {
                name: "post",
                path: "post/:id/:url",
                element: <BlogsDetail />,
              },
            ],
          },
          {
            name: "contact",
            path: "contact",
            element: <Contact />,
          },
          {
            name: "login",
            path: "login",
            element: <Login />,
          },
          {
            name: "register",
            path: "register",
            element: <Register />,
          },
          {
            name: "profile",
            path: "profile",
            element: <ProfileLayout />,
            auth: true,
            children: [
              {
                name: "index",
                index: true,
                element: <Navigate to="/" replace={true} />, // TODO: Import user id here.
              },
              {
                name: "user",
                path: "user/:id",
                element: <Profile />,
              },
            ],
          },
        ],
      },
      {
        name: "profile",
        path: "en/profile",
        element: <ProfileLayout />,
        auth: true,
        children: [
          {
            name: "index",
            index: true,
            element: <Navigate to="/" replace={true} />, // TODO: Import user id here.
          },
          {
            name: "user",
            path: "user/:id",
            element: <Profile />,
          },
        ],
      },
    ],
  },
  {
    name: "admin",
    path: "/admin/",
    element: <AdminPersistLogin />,
    children: [
      {
        name: "index",
        index: true,
        adminAuth: true,
        element: <Navigate to="blog-management" replace={true} />,
      },
      {
        name: "blogManagement",
        path: "blog-management",
        adminAuth: true,
        element: <AdminBlogLayout />,
        children: [
          {
            name: "index",
            index: true,
            element: <BlogManagement />,
          },
          {
            name: "addblog",
            path: "add-blog",
            element: <AddBlog />,
          },
          {
            name: "post",
            path: ":id",
            element: <AdminBlogDetail />,
          },
        ],
      },
      {
        name: "categories",
        path: "categories",
        adminAuth: true,
        element: <Categories />,
      },

      {
        name: "users",
        path: "users",
        adminAuth: true,
        element: <UserLayout />,
        children: [
          {
            name: "index",
            index: true,
            element: <Users />,
          },
          {
            name: "adduser",
            path: "add-user",
            element: <AddUser />,
          },
          {
            name: "user",
            path: ":id",
            element: <EditUser />,
          },
        ],
      },
      {
        name: "comments",
        path: "comments",
        adminAuth: true,
        element: <CommentLayout />,
        children: [
          {
            name: "index",
            index: true,
            element: <CommentList />,
          },
          {
            name: "edit",
            path: "comment/:id",
            element: <EditComment />,
          },
        ],
      },
     
      {
        name: "settings",
        path: "settings",
        adminAuth: true,
        element: <Settings />,
      },
    ],
  },
  // {
  //   name: "admin-login",
  //   path: "/admin/login",
  //   element: <AdminLogin />,
  // },
  {
    name: "notFound",
    path: "*",
    element: <NotFound />,
  },
];

const authMap = (routes) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = <PrivateRouter>{route.element}</PrivateRouter>;
    }
    if (route?.adminAuth) {
      route.element = <AdminPrivateRouter>{route.element}</AdminPrivateRouter>;
    }
    if (route?.children) {
      route.children = authMap(route.children);
    }
    return route;
  });

export default authMap(routes);
