import React from "react";
import { RiCloseCircleLine } from "react-icons/ri";

const ForgotModal = ({ setForgotModal, forgotModal }) => {
  const handleChangePassword = () => {
    setForgotModal(false);
  };

  return (
    <div className={`modal-container ${forgotModal && "active"} `}>
      <div className="modal-body">
        <form className="mb-8">
          <div className="flex justify-end mr-5">
            <RiCloseCircleLine
              className="text-right text-[#23242a] text-3xl mb-3 cursor-pointer"
              onClick={handleChangePassword}
            />
          </div>
          <p className="text-[#23242a] text-center">
            Do you want to change your password?
          </p>
          <div className="flex justify-center">
            <button
              type="button"
              className="rounded-lg shadow-md p-2 mt-4 dark:bg-[#23242a]"
            >
              Change
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotModal;
