import { useEffect, useState } from "react";
import apiService from "../api/apiService";

const Categories = ({ t }) => {
  const [categories, setCategories] = useState([]);


  const getCategories = () => {
    apiService
      .getCategories()
      .then((response) => {
        const data = response.data;
        setCategories(data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="flex flex-col items-stretch md:w-2/12 w-8/12 md:mr-auto mx-auto mt-5">
      <div className="static md:sticky top-5">
        <div className="flex flex-col w-full space-y-2">
          <a href="/" className="flex items-center justify-between w-full ">
            <span className="text-xs font-bold uppercase mb-2 pt-2 pb-1">
              {t("Categories")}
            </span>
          </a>

          <div className="flex w-full h-1 bg-opacity-10 dark:bg-yellow-400">
            <div className="w-1/2 h-full dark:bg-yellow-400"></div>
          </div>
        </div>

        <div className="flex flex-col space-y-8 mt-2 md:space-y-12">
          <div className="flex flex-col space-y-2">
             {categories.map(({ id, name }) => (
              <h3
                key={id}
                className="flex items-center space-x-3 text-gray-400 pt-3 pb-3"
              >
                <span className="relative flex-shrink-0 w-2 h-2 rounded-full dark:bg-yellow-400">
                  <span className="absolute flex-shrink-0 w-3 h-3 rounded-full -left-1 -top-1 animate-ping dark:bg-yellow-400"></span>
                </span>
                <span className="text-xs font-bold tracking-wider uppercase">
                  {name}
                </span>
              </h3>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
