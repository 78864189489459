import { generatePath } from "react-router-dom";
import routes from "../router/routes";

export const url = (path, params = {}) => {
  let lastRoute, url;

  path.split(".").map((p) => {
    if (!lastRoute) {
      lastRoute = routes.find((r) => r.name === p);
      return (url = lastRoute.path);
    } else {
      lastRoute = lastRoute.children.find((r) => r.name === p);
      return (url += "/" + lastRoute.path);
    }
  });

  return generatePath(url.replace("//", "/"), params);
};
