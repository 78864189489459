import { Link, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Comments = ({ comments, t, i18n }) => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <section className="py-8 lg:py-16 comments">
      <div className="max-w-2xl mx-auto px-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg lg:text-2xl font-bold">
            {t("Comments")} ({comments})
          </h2>
        </div>
        {user ? (
          <form className="mb-6">
            <div className="py-2 px-4 mb-4 rounded-lg rounded-t-lg border border-gray-200 comment-area">
              <label htmlFor="comment" className="sr-only">
                {t("Your comment")}
              </label>
              <textarea
                id="comment"
                rows={6}
                className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400"
                placeholder={t("Write a comment")}
                required
                defaultValue={""}
              />
            </div>
            <button
              type="submit"
              className="comment-btn inline-flex items-center py-2.5 px-4 text-xs font-medium text-center rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900"
            >
              {t("Post comment")}
            </button>
          </form>
        ) : (
          <div className="flex flex-col gap-5 mb-10">
            {t("Login Message")}
            <div className="flex items-center gap-3 mx-auto">
              <Link
                to={`/${i18n.language.split("-")[0]}/login`}
                state={{
                  return_url: location.pathname,
                }}
                className="btns"
                type="button"
              >
                {t("Login")}
              </Link>
              or
              <Link
                to={`/${i18n.language.split("-")[0]}/register`}
                state={{
                  return_url: location.pathname,
                }}
                className="btns"
                type="button"
              >
                {t("Register")}
              </Link>
            </div>
          </div>
        )}

        <article className="p-6 mb-6 text-base rounded-lg">
          <footer className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <img
                  className="mr-2 w-6 h-6 rounded-full"
                  src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"
                  alt="Michael Gough"
                />
                Michael Gough
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                <time
                  pubdate="true"
                  dateTime="2022-02-08"
                  title="February 8th, 2022"
                >
                  Feb. 8, 2022
                </time>
              </p>
            </div>
          </footer>
          <p className="text-gray-500 dark:text-gray-400">
            Very straight-to-point article. Really worth time reading. Thank
            you! But tools are just the instruments for the UX designers. The
            knowledge of the design tools are as important as the creation of
            the design strategy.
          </p>
          <div className="flex items-center mt-4 space-x-4">
            <button
              type="button"
              className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400"
            >
              <svg
                aria-hidden="true"
                className="mr-1 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              {t("Reply")}
            </button>
          </div>
        </article>
        <article className="p-6 mb-6 ml-6 lg:ml-12 text-base rounded-lg">
          <footer className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <img
                  className="mr-2 w-6 h-6 rounded-full"
                  src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                  alt="Jese Leos"
                />
                Jese Leos
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                <time
                  pubdate="true"
                  dateTime="2022-02-12"
                  title="February 12th, 2022"
                >
                  Feb. 12, 2022
                </time>
              </p>
            </div>
          </footer>
          <p className="text-gray-500 dark:text-gray-400">
            Much appreciated! Glad you liked it ☺️
          </p>
          <div className="flex items-center mt-4 space-x-4">
            <button
              type="button"
              className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400"
            >
              <svg
                aria-hidden="true"
                className="mr-1 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              {t("Reply")}
            </button>
          </div>
        </article>
        <article className="p-6 mb-6 text-base rounded-lg">
          <footer className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <img
                  className="mr-2 w-6 h-6 rounded-full"
                  src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
                  alt="Bonnie Green"
                />
                Bonnie Green
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                <time
                  pubdate="true"
                  dateTime="2022-03-12"
                  title="March 12th, 2022"
                >
                  Mar. 12, 2022
                </time>
              </p>
            </div>
          </footer>
          <p className="text-gray-500 dark:text-gray-400">
            The article covers the essentials, challenges, myths and stages the
            UX designer should consider while creating the design strategy.
          </p>
          <div className="flex items-center mt-4 space-x-4">
            <button
              type="button"
              className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400"
            >
              <svg
                aria-hidden="true"
                className="mr-1 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              {t("Reply")}
            </button>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Comments;
