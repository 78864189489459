import axios from "axios";

const BASE_URL = "https://sarikaya.pythonanywhere.com/";
// const BASE_URL = "http://localhost:8000/";

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivateInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Authorization": `Bearer accessToken`
  },
});

const apiService = {
  getCategories: async () => {
    try {
      return await axios.get(`${BASE_URL}api/categories/`);
    } catch (error) {
      console.error("Error getting categories: ", error);
      throw error;
    }
  },

  getCategory: async (id) => {
    try {
      return await axiosInstance.get(`${BASE_URL}api/category/${id}/`);
    } catch (error) {
      console.error(`Error getting category with ID ${id}: `, error);
      throw error;
    }
  },

  createCategory: async (categoryData) => {
    try {
      return await axiosPrivateInstance.post(`${BASE_URL}api/category/create/`, categoryData);
    } catch (error) {
      console.error("Error creating category: ", error);
      throw error;
    }
  },

  updateCategory: async (id, categoryData) => {
    try {
      return await axiosPrivateInstance.patch(
        `${BASE_URL}api/category/update/${id}/`,
        categoryData
      );
    } catch (error) {
      console.error(`Error updating category with ID ${id}: `, error);
      throw error;
    }
  },

  deleteCategory: async (id) => {
    try {
      return await axiosPrivateInstance.delete(`${BASE_URL}api/category/update/${id}/`);
    } catch (error) {
      console.error(`Error deleting category with ID ${id}: `, error);
      throw error;
    }
  },

  getBlogs: async (page) => {
    try {
      return await axios.get(`${BASE_URL}api/blogs/?offset=${page}`);
    } catch (error) {
      console.error("Error getting blogs: ", error);
      throw error;
    }
  },

  getBlog: async (id, lang) => {
    try {
      return await axios.get(`${BASE_URL}api/blog_detail/${lang}/${id}/`);
    } catch (error) {
      console.error(`Error getting blog with ID ${id}: `, error);
      throw error;
    }
  },

  createBlog: async (blogData) => {
    try {
      return await axiosPrivateInstance.post(`${BASE_URL}/api/blog_create/`, blogData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    } catch (error) {
      console.error("Error creating blog: ", error);
      throw error;
    }
  },

  updateBlog: async (id, blogData) => {
    try {
      return await axiosPrivateInstance.patch(`${BASE_URL}api/blog_update/${id}/`, blogData);
    } catch (error) {
      console.error(`Error updating blog with ID ${id}: `, error);
      throw error;
    }
  },

  deleteBlog: async (id) => {
    try {
      return await axiosPrivateInstance.delete(`${BASE_URL}api/blog_delete/${id}/`);
    } catch (error) {
      console.error(`Error deleting blog with ID ${id}: `, error);
      throw error;
    }
  },

  getComments: async () => {
    try {
      return await axios.get(`${BASE_URL}api/comments/`);
    } catch (error) {
      console.error("Error getting comments: ", error);
      throw error;
    }
  },

  getComment: async (id) => {
    try {
      return await axiosPrivateInstance.get(`${BASE_URL}api/comment/${id}/`);
    } catch (error) {
      console.error(`Error getting comment with ID ${id}: `, error);
      throw error;
    }
  },

  createComment: async (commentData) => {
    try {
      return await axiosPrivateInstance.post(`${BASE_URL}/api/comment_create/`, commentData);
    } catch (error) {
      console.error("Error creating comment: ", error);
      throw error;
    }
  },

  updateComment: async (id, commentData) => {
    try {
      return await axiosPrivateInstance.patch(`${BASE_URL}api/comment/${id}/`, commentData);
    } catch (error) {
      console.error(`Error updating comment with ID ${id}: `, error);
      throw error;
    }
  },

  deleteComment: async (id) => {
    try {
      return await axiosPrivateInstance.delete(`${BASE_URL}api/comment/${id}/`);
    } catch (error) {
      console.error(`Error deleting comment with ID ${id}: `, error);
      throw error;
    }
  },

  getGroups: async () => {
    try {
      return await axiosPrivateInstance.get(`${BASE_URL}auth/groups/`);
    } catch (error) {
      console.error("Error getting groups: ", error);
      throw error;
    }
  },

  getUsers: async () => {
    try {
      return await axiosPrivateInstance.get(`${BASE_URL}auth/users/`);
    } catch (error) {
      console.error("Error getting users: ", error);
      throw error;
    }
  },

  getUser: async (id) => {
    try {
      return await axiosPrivateInstance.get(`${BASE_URL}auth/users/${id}/`);
    } catch (error) {
      console.error(`Error getting user with ID ${id}: `, error);
      throw error;
    }
  },

  createUser: async (userData) => {
    try {
      return await axiosPrivateInstance.post(`${BASE_URL}/auth/users/`, userData);
    } catch (error) {
      console.error("Error creating user: ", error);
      throw error;
    }
  },

  updateUser: async (id, userData) => {
    try {
      return await axiosPrivateInstance.put(`${BASE_URL}auth/users/${id}/`, userData);
    } catch (error) {
      console.error(`Error updating user with ID ${id}: `, error);
      throw error;
    }
  },

  deleteUser: async (id) => {
    try {
      return await axiosPrivateInstance.delete(`${BASE_URL}auth/users/${id}/`);
    } catch (error) {
      console.error(`Error deleting user with ID ${id}: `, error);
      throw error;
    }
  },
};

export default apiService;
