import {
  frontendSkills,
  backendSkills,
  database,
  CICDPipeline,
  operatingSystem,
  userInterfaceExperience,
  softSkills,
} from "../utils/data";
import { IoIosArrowForward } from "react-icons/io";
import Tech from "../assets/tech.gif";
import DevModal from "../components/DevModal";
import { useState } from "react";
import { useDesc, useTitle, useKeywords } from "../helpers/useSeo";
import { useTranslation } from "react-i18next";

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // TODO: CHANGE LANGUAGE AND ADD IT TO ADMIN PANEL
  const { t } = useTranslation();

  useTitle(t("About Me useTitle"));
  useDesc(t("About Me useDesc"));
  useKeywords(t("About Me useKeywords"));

  return (
    <>
      {isModalOpen && <DevModal setIsModalOpen={setIsModalOpen} />}
      <div className="about container flex flex-col items-center rounded-md mx-auto mt-10 mb-20 shadow-lg px-8 pb-8 gap-10">
        <div className="about-header flex flex-col md:flex-row w-full justify-between items-center md:items-start gap-10 border-b pb-4">
          <div className="profile-img md:w-3/12 w-full flex flex-col items-center gap-4">
            <img
              src="https://avatars.githubusercontent.com/u/94994862?v=4"
              alt="Sinan Sarikaya"
              className="rounded-full w-full drop-shadow-lg grayscale hover:grayscale-0 transition-all ease duration-1000"
            />
            <h4 className="lg:text-base text-sm text-center">
              Full-Stack Web Developer
            </h4>
            <h4 className="text-base">Sinan Sarikaya</h4>
          </div>
          <div className="md:w-9/12 w-full">
            <div className="about-title text-center my-6 text-2xl">
              {t("About Me Title")}
            </div>
            <article className="about-content leading-8 tracking-wide">
              <p className="mb-5 paragraph">{t("About Me P1")}</p>
              <p className="mb-5 paragraph">{t("About Me P2")}</p>
              <p className="mb-5 paragraph">{t("About Me P3")}</p>
            </article>
          </div>
        </div>
        <div className="about-body w-full flex flex-col gap-5 ">
          <div className="hard-skills mt-8 p-8">
            <h1 className="text-2xl underline underline-offset-1 text-center uppercase">
              {t("Technical Skills")}
            </h1>
            <div className="flex flex-col mb-8 mt-8">
              <h2 className="flex text-lg font-bold">
                <img src={Tech} width="40" alt="" />
                <span className="p-2">{t("Frontend")}</span>
                <IoIosArrowForward className="pt-2 text-3xl" />
              </h2>
              <p className="flex flex-wrap gap-3">
                {frontendSkills.map((frontend) => (
                  <span className="skills ml-8" key={frontend.id}>
                    <span
                      className="tags-text whitespace-nowrap select-none text-gray-400"
                      data-text={frontend.skill}
                    >
                      {frontend.skill}
                    </span>
                  </span>
                ))}
              </p>
            </div>

            <div className="flex flex-col mb-8 mt-8">
              <h2 className="flex text-lg font-bold">
                <img src={Tech} width="40" alt="" />
                <span className="p-2">{t("Backend")}</span>
                <IoIosArrowForward className="pt-2 text-3xl" />
              </h2>
              <p className="flex flex-wrap gap-3">
                {backendSkills.map((backend) => (
                  <span className="skills ml-8" key={backend.id}>
                    <span
                      className="tags-text whitespace-nowrap select-none text-gray-400"
                      data-text={backend.skill}
                    >
                      {backend.skill}
                    </span>
                  </span>
                ))}
              </p>
            </div>

            <div className="flex flex-col mb-8 mt-8">
              <h2 className="flex text-lg font-bold">
                <img src={Tech} width="40" alt="" />
                <span className="p-2">{t("Database")}</span>
                <IoIosArrowForward className="pt-2 text-3xl" />
              </h2>
              <p className="flex flex-wrap gap-3">
                {database.map((database) => (
                  <span className="skills ml-8" key={database.id}>
                    <span
                      className="tags-text whitespace-nowrap select-none text-gray-400"
                      data-text={database.skill}
                    >
                      {database.skill}
                    </span>
                  </span>
                ))}
              </p>
            </div>

            <div className="flex flex-col mb-8 mt-8">
              <h2 className="flex text-lg font-bold">
                <img src={Tech} width="40" alt="" />
                <span className="p-2">CI/CD</span>
                <IoIosArrowForward className="pt-2 text-3xl" />
              </h2>
              <p className="flex flex-wrap gap-3">
                {CICDPipeline.map((cicd) => (
                  <span className="skills ml-8" key={cicd.id}>
                    <span
                      className="tags-text whitespace-nowrap select-none text-gray-400"
                      data-text={cicd.skill}
                    >
                      {cicd.skill}
                    </span>
                  </span>
                ))}
              </p>
            </div>

            <div className="flex flex-col mb-8 mt-8">
              <h2 className="flex text-lg font-bold">
                <img src={Tech} width="40" alt="" />
                <span className="p-2">OS</span>
                <IoIosArrowForward className="pt-2 text-3xl" />
              </h2>
              <p className="flex flex-wrap gap-3">
                {operatingSystem.map((os) => (
                  <span className="skills ml-8" key={os.id}>
                    <span
                      className="tags-text whitespace-nowrap select-none text-gray-400"
                      data-text={os.skill}
                    >
                      {os.skill}
                    </span>
                  </span>
                ))}
              </p>
            </div>

            <div className="flex flex-col mb-8 mt-8">
              <h2 className="flex text-lg font-bold">
                <img src={Tech} width="40" alt="" />
                <span className="p-2">UI</span>
                <IoIosArrowForward className="pt-2 text-3xl" />
              </h2>
              <p className="flex flex-wrap gap-3">
                {userInterfaceExperience.map((ui) => (
                  <span className="skills ml-8" key={ui.id}>
                    <span
                      className="tags-text whitespace-nowrap select-none text-gray-400"
                      data-text={ui.skill}
                    >
                      {ui.skill}
                    </span>
                  </span>
                ))}
              </p>
            </div>
          </div>

          <div className="soft-skills p-8">
            <h2 className="text-2xl mb-3 underline underline-offset-1 text-center uppercase">
              {t("Soft Skills")}
            </h2>
            <p className="flex flex-wrap gap-3">
              {softSkills.map((soft) => (
                <span className="skills" key={soft.id}>
                  <span
                    className="tags-text whitespace-nowrap select-none text-gray-400"
                    data-text={t(soft.skill)}
                  >
                    {t(soft.skill)}
                  </span>
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
