import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NoLayout = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      i18n.changeLanguage("no");
    }, 1000);
    return () => clearTimeout(timeout);
  }, [i18n]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default NoLayout;
