import { Link, useLocation } from "react-router-dom";
import { getLocation } from "../../helpers/getLocation";
import apiService from "../../../api/apiService";
import { useEffect, useState } from "react";
import { useAlert } from "../../../context/AlertContext";
import { AiTwotoneEdit, AiFillDelete } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { url } from "../../../utils/url";

const CommentList = () => {
  const location = useLocation();
  const { setAlertMessage } = useAlert();
  const [comments, setComments] = useState([]);

  const getComments = () => {
    apiService
      .getComments()
      .then((response) => {
        const data = response.data;
        setComments(data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getComments();
  }, []);

  const deleteComment = (id) => {
    apiService
      .deleteComment(id)
      .then(() => {
        setAlertMessage({
          msg: "The category has been successfully deleted.",
          type: "warning",
        });
        getComments();
      })
      .catch((error) => {
        setAlertMessage({
          msg: `An error occurred while deleting the category. ${error}`,
          type: "error",
        });
        console.error(error);
      });
  };

  return (
    <section className="dashboard__section flex flex-col">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className=" sm:text-2xl text-lg font-bold tracking-tight">
          Comment List
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>
      <div className="flex flex-wrap-reverse xl:flex-nowrap gap-9 w-full">
        <div
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
          className="w-full bg-white py-10 px-6 rounded-md ml-auto"
        >
          <table className="w-full border text-sm text-left">
            <thead className="text-xs uppercase bg-[#5ab7ac] text-[#fff]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  User
                </th>
                <th scope="col" className="px-6 py-3">
                  Blog Post
                </th>
                <th scope="col" className="px-6 py-3">
                  Date/Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Visibility Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {comments.map(
                ({ id, user, blog, text, created_at, visibility_status }) => (
                  <tr
                    key={id}
                    className="text-gray-600 border-b hover:bg-[#5ab7ac30]"
                  >
                    <td className="px-6 py-4">{id}</td>
                    <td className="px-6 py-4">{user.email}</td>
                    <td className="px-6 py-4">{blog.title_en}</td>
                    <td className="px-6 py-4">
                      {new Date(created_at).toLocaleString()}
                    </td>
                    <td className="px-6 py-4">
                      {visibility_status ? "True" : "False"}
                    </td>
                    <td className="px-6 py-4 flex gap-5 text-lg">
                    <Link
                        to={url("admin.comments.edit", { id: [id] })}
                        className="font-medium text-[#5ab7ac] hover:text-[#5ab7ac57] transition ease-in-out duration-500"
                      >
                        <AiTwotoneEdit />
                      </Link>
                      <span
                        onClick={() => deleteComment(id)}
                        className="font-medium text-red-700 hover:text-red-500 transition ease-in-out duration-500 cursor-pointer"
                      >
                        <AiFillDelete />
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default CommentList;
