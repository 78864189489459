import { AiFillHeart } from "react-icons/ai";
import Search from "../../components/Search";
import Categories from "../../components/Categories";
import { Link } from "react-router-dom";
import { url } from "../../utils/url";
import { useDesc, useTitle, useKeywords } from "../../helpers/useSeo";
import { blogList } from "../../utils/data";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const { t, i18n } = useTranslation();

  useTitle(t("Blogs useTitle"));
  useDesc(t("Blogs useDesc"));
  useKeywords(t("Blogs useKeywords"));


  return (
    <section className="blogs container mx-auto mb-20 flex justify-center md:flex-row flex-col">
      <Categories t={t} />
      <div className="md:ml-auto w-11/12 md:w-9/12 flex flex-col gap-8">
        <Search t={t} />
        {blogList[i18n.language.split("-")[0]].map(
          ({
            id,
            author,
            title,
            date,
            description,
            image,
            imageFilter,
            keywords,
            seoUrl,
          }) => (
            <div
              key={id}
              className="rounded-lg blog-card max-w-6xl mx-auto overflow-hidden space-y-6 md:space-y-12"
            >
              <Link
                to={url(`main.${i18n.language.split("-")[0]}.blogs.post`, {
                  id: id,
                  url: seoUrl,
                })}
                className="block max-w-md gap-3 mx-auto md:max-w-full group md:grid md:grid-cols-12"
              >
                <img
                  src={image}
                  alt={title}
                  className={`${imageFilter} object-cover w-full h-[450px] md:h-100 md:col-span-7 dark:bg-gray-500`}
                />
                <article className="p-6 space-y-2 md:col-span-5">
                  <div className="flex pb-3">
                    <span className="text-xs pt-2 dark:text-gray-400">
                      {date}
                    </span>
                  </div>
                  <h1 className="text-2xl font-semibold md:text-2xl">
                    {title}
                  </h1>
                  <p className="pt-3 indent-8">
                    {description.substring(0, 100)}...
                  </p>
                  <div className="flex flex-row-reverse pt-3">
                    <p className="text-md author">{author}</p>
                  </div>
                  <div className="flex flex-wrap pt-4">
                    {keywords.map((keyword) => (
                      <div
                        key={keyword}
                        className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-yellow-100 bg-yellow-700 border border-yellow-700"
                      >
                        <div slot="avatar">
                          <AiFillHeart className="w-3 mr-1" />
                        </div>
                        <div className="text-xs font-normal leading-none max-w-full flex-initial">
                          {keyword}
                        </div>
                      </div>
                    ))}
                  </div>
                </article>
              </Link>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default Blogs;
