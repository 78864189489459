import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../context/AlertContext";
import { getLocation } from "../../helpers/getLocation";
import { useEffect, useRef, useState } from "react";
import apiService from "../../../api/apiService";
import { object, string } from "yup";
import { handleValidationAndBackendErrors } from "../../../utils/validation";

let userSchema = object({
  first_name: string().required("First name is required"),
  last_name: string().required("Last name is required"),
  email: string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: string().min(4, "Password must be at least 6 characters"),
});

const EditUser = () => {
  const { id } = useParams();
  const location = useLocation();
  const { setAlertMessage } = useAlert();
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [userInfo, setUserInfo] = useState({
    groups: [],
    first_name: "",
    last_name: "",
    email: "",
  });
  const [groups, setGroups] = useState([]);

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
  });

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const isAdminRef = useRef();
  const isStaffRef = useRef();

  const navigate = useNavigate();

  const handleInputChange = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    setLoading(false);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setLoading(true);
        const response = await apiService.getGroups();
        const data = response.data;
        setGroups(data.results);
        retryCount = 0;
      } catch (error) {
        console.log(error);
        retryCount++;
        if (retryCount <= maxRetry) {
          fetchGroups();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  const handleGroupChange = (groupId, isChecked) => {
    const updatedGroups = isChecked
      ? [...userInfo?.groups, groupId]
      : userInfo?.groups.filter((g) => g.id !== groupId);
  
    setUserInfo((prevUserInfo) => ({ ...prevUserInfo, groups: updatedGroups }));
  };
  

  const maxRetry = 3;
  let retryCount = 0;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const response = await apiService.getUser(id);
        const data = response.data;
        setUserInfo(data);
        retryCount = 0;
      } catch (error) {
        console.log(error);
        retryCount++;
        if (retryCount <= maxRetry) {
          fetchUser();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const group_info = [{id: 1, name: "Editor"}]

    const userData = {
      first_name: capitalizeFirstLetter(firstNameRef.current.value),
      last_name: capitalizeFirstLetter(lastNameRef.current.value),
      email: emailRef.current.value.toLowerCase(),
      // groups: userInfo.groups,
      groups: group_info,
      is_admin: isAdminRef.current.checked,
      is_staff: isStaffRef.current.checked,
    };

    if (passwordRef.current.value !== "") {
      userData["password"] = passwordRef.current.value;
    }

    try {
      const response = await handleValidationAndBackendErrors(
        userData,
        userSchema,
        setErrors,
        () => apiService.updateUser(userInfo?.id, userData)
      );
      if (response?.validationError) {
        return;
      }

      setAlertMessage({
        msg: "User information has been successfully updated.",
        type: "success",
      });

      navigate("/admin/users");
    } catch (error) {
      setAlertMessage({
        msg: `An error occurred while updating the user. ${error}`,
        type: "error",
      });
    }
  };

  return loading ? (
    "Loading"
  ) : (
    <section className="dashboard__section">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          {userInfo?.first_name} {userInfo?.last_name}
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}/
          {userInfo?.first_name
            .replace(/\s+/g, "-")
            .replace(/[^a-zA-Z0-9-]/g, "")
            .toLowerCase()}
        </span>
      </div>

      <div
        style={{
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
        className="flex flex-col w-full px-6 rounded-lg bg-white"
      >
        <div className="xl:w-10/12 w-full mx-auto py-10">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <div className="w-full">
                <label
                  htmlFor="firstname"
                  className="mb-2 inline-block text-neutral-70"
                >
                  First Name
                </label>
                <input
                  id="firstname"
                  type="text"
                  className={`capitalize border-solid border-2 ${
                    errors.first_name ? "border-red-500" : "border-[#eee]"
                  } text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm`}
                  placeholder="First Name"
                  name="first_name"
                  ref={firstNameRef}
                  defaultValue={userInfo?.first_name}
                  onChange={() => handleInputChange("first_name")}
                />
                {errors.first_name && (
                  <p className="text-red-500 mt-2">{errors.first_name}</p>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mt-5 w-full">
                <label
                  htmlFor="lastname"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Last Name
                </label>
                <input
                  id="lastname"
                  type="text"
                  className={`capitalize border-solid border-2 ${
                    errors.last_name ? "border-red-500" : "border-[#eee]"
                  } text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm`}
                  placeholder="Last Name"
                  name="last_name"
                  ref={lastNameRef}
                  defaultValue={userInfo?.last_name}
                />
                {errors.last_name && (
                  <p className="text-red-500 mt-2">{errors.last_name}</p>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mt-5 w-full">
                <label
                  htmlFor="email"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className={`lowercase border-solid border-2 ${
                    errors.email ? "border-red-500" : "border-[#eee]"
                  } text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm`}
                  placeholder="Email"
                  name="email"
                  ref={emailRef}
                  defaultValue={userInfo?.email}
                  onChange={() => handleInputChange("email")}
                />
                {errors.email && (
                  <p className="text-red-500 mt-2">{errors.email}</p>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mt-5 w-full relative">
                <label
                  htmlFor="password"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Password
                </label>
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  className={`border-solid border-2 ${
                    errors.password ? "border-red-500" : "border-[#eee]"
                  } text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm`}
                  placeholder="Password"
                  name="password"
                  ref={passwordRef}
                  onChange={() => handleInputChange("password")}
                />
                {errors.password && (
                  <p className="text-red-500 mt-2">{errors.password}</p>
                )}

                <button
                  type="button"
                  className="absolute top-11 right-2 transform"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <div className="w-full">
                <label
                  htmlFor="groups"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Groups
                </label>
                {groups.map((group) => (
                  <div className="flex mt-5 gap-1" key={group.id}>
                    <input
                      type="checkbox"
                      id={`group-${group?.id}`}
                      value={group?.id}
                      defaultChecked={userInfo?.groups?.some((g) => g.id === group.id)}
                      onChange={(event) => {
                        handleGroupChange(group?.id, event.target.checked);
                      }}
                      className="h-[1.1rem] w-[1.1rem]"
                    />
                    <label
                      htmlFor={`group-${group.id}`}
                      className="text-gray-900"
                    >
                      {group.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex mt-5 gap-1">
              <input
                className="h-[1.1rem] w-[1.1rem]"
                type="checkbox"
                id="is_active"
                defaultChecked={userInfo?.is_active}
                name="is_active"
              />
              <label htmlFor="is_active">Active</label>
            </div>
            <div className="flex mt-5 gap-1">
              <input
                className="h-[1.1rem] w-[1.1rem]"
                type="checkbox"
                id="is_admin"
                defaultChecked={userInfo?.is_admin}
                name="is_admin"
                ref={isAdminRef}
              />
              <label htmlFor="is_admin">Admin</label>
            </div>
            <div className="flex mt-5 gap-1">
              <input
                className="h-[1.1rem] w-[1.1rem]"
                type="checkbox"
                id="is_staff"
                defaultChecked={userInfo?.is_staff}
                name="is_staff"
                ref={isStaffRef}
              />
              <label htmlFor="is_staff">Staff</label>
            </div>

            <div className="flex justify-center">
              <div className="mt-5 w-full">
                <label
                  htmlFor="created_at"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Created At
                </label>
                <input
                  id="created_at"
                  type="text"
                  className="border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
                  defaultValue={new Date(userInfo?.created_at).toLocaleString()}
                  readOnly
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="mt-5 w-full">
                <label
                  htmlFor="updated_at"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Updated At
                </label>
                <input
                  id="updated_at"
                  type="text"
                  className="border-solid border-2 border-[#eee] text-slate-500 focus:outline-none w-full bg-white placeholder-slate-400 rounded-sm p-3 py-3 text-sm"
                  defaultValue={new Date(
                    userInfo?.updated_at
                  )?.toLocaleString()}
                  readOnly
                />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <button
                type="submit"
                className="mt-5 focus:outline-none rounded-sm text-sm w-full py-2 text-center text-[#fff] bg-[#5ab7ac] hover:bg-[#5ab7acd7]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditUser;
