import { IoIosPricetag } from "react-icons/io";
import { BiCommentDots } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import {
  AiOutlineArrowRight,
  AiTwotoneEdit,
  AiTwotoneDelete,
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { getLocation } from "../../helpers/getLocation";
import { url } from "../../../utils/url";
import { useEffect, useState } from "react";
import apiService from "../../../api/apiService";
import { Parser } from "html-to-react";
import Pagination from "../../components/Pagination";

const BlogManagement = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [page, setPage] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);

  const fetchData = async (apiMethod, page, setData) => {
    try {
      const { data } = await apiMethod(page);
      if (apiMethod === apiService.getBlogs) {
        // sort blogs by date
        data.results.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setNextPage(data.next);
        setPrevPage(data.previous);
        setTotalBlogs(data.count);
      }
      setData(data.results);
    } catch (error) {
      console.error(error);
      console.log("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    fetchData(apiService.getCategories, 1, setCategories);
    fetchData(apiService.getBlogs, page, setBlogs);
  }, [page]);

  const deleteBlog = async (id) => {
    try {
      await apiService.deleteBlog(id);
      setBlogs(blogs.filter((blog) => blog.id !== id));
    } catch (error) {
      console.error(error);
      console.log(
        "Something went wrong while deleting the blog. Please try again."
      );
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredBlogList = blogs.filter((blog) =>
    blog.title_en.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="dashboard__section">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          Blog Management
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>
      <div className="flex flex-wrap-reverse xl:flex-nowrap gap-9 w-full">
        <div
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
          className="flex flex-col divide-y xl:w-9/12 w-full px-6 rounded-lg bg-white"
        >
          {filteredBlogList?.length > 0 ? (
            filteredBlogList?.map(
              ({
                id,
                title_en,
                created_at,
                category,
                // comments,
                content_en,
                blog_image,
                image_filter,
                slug_en,
              }) => (
                <article key={id} className="w-full sm:w-10/12 mx-auto py-10">
                  <div className="w-full flex justify-between text-gray-500">
                    <div className="mb-2">
                      <h5 className="mb-2 text-xl font-bold">{title_en}</h5>
                      <h6 className="text-sm">
                        {new Date(created_at).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </h6>
                    </div>
                    <div>
                      <span
                        className="
                  dropdown-toggle
                  p-2
                  cursor-pointer
                  transition
                  duration-150
                  ease-in-out
                  flex
                  items-center
                  whitespace-nowrap 
                "
                        type="button"
                        id="blogSet"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                        </svg>
                      </span>
                      <ul
                        className="
                        dropdown-menu
                        min-w-max
                        absolute
                        hidden
                        bg-white
                        text-base
                        z-50
                        float-left
                        py-2
                        list-none
                        text-left
                        rounded-lg
                        shadow-lg
                        mt-1
                        m-0
                        p-8
                        bg-clip-padding 
                      "
                        aria-labelledby="blogSet"
                      >
                        <li className="mt-3 mb-3">
                          <Link
                            className="inline-flex items-baseline cursor-pointer"
                            to={url("admin.blogManagement.post", {
                              id: [id],
                            })}
                          >
                            <AiTwotoneEdit className="mr-2" /> Edit
                          </Link>
                        </li>
                        <li className="mt-3 mb-3">
                          <p
                            className="inline-flex items-baseline cursor-pointer"
                            onClick={() => deleteBlog(id)}
                          >
                            <AiTwotoneDelete className="mr-2" /> Delete
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <img
                    className={`${image_filter} h-[450px] select-none w-full object-cover`}
                    src={blog_image}
                    alt={title_en}
                  />
                  <div className="mt-4">
                    <dl className="mt-4 text-xs font-medium flex flex-wrap items-center row-start-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
                      <dd className="flex gap-2 items-center">
                        <IoIosPricetag />
                        <span> {category.name}</span>
                      </dd>
                      <dt className="sr-only">Location</dt>
                      <dd className="flex gap-2 items-center">
                        <svg
                          width="2"
                          height="2"
                          aria-hidden="true"
                          fill="currentColor"
                          className="mx-3 text-slate-300"
                        >
                          <circle cx="1" cy="1" r="1" />
                        </svg>
                        {/* <BiCommentDots /> {comments} Comments */}
                      </dd>
                    </dl>
                  </div>
                  <div className="mt-4">
                    <div className="mb-3 text-gray-500 leading-7">
                      {Parser().parse(content_en.substring(0, 100))}
                    </div>
                    <dd className="flex items-center cursor-pointer text-xs mt-5">
                      <Link
                        to={url("admin.blogManagement.post", { id: [slug_en] })}
                      >
                        Read more
                      </Link>
                      <AiOutlineArrowRight className="mx-2" />
                    </dd>
                  </div>
                </article>
              )
            )
          ) : (
            <p className="m-auto">No blogs found. Try creating one!</p>
          )}

          <Pagination
            count={totalBlogs}
            setPage={setPage}
            currentPage={page}
            setNextPage={setNextPage}
            nextPage={nextPage}
            setPrevPage={setPrevPage}
            prevPage={prevPage}
          />
        </div>
        <div
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
          className="xl:w-3/12 w-full bg-white py-5 px-6 rounded-md ml-auto"
        >
          <Link to={url("admin.blogManagement.addblog")}>
            <button className="btns mb-4 w-full">Add a new blog</button>
          </Link>
          <h4 className="mb-3 text-lg font-bold tracking-tight">Search</h4>
          <form className="group relative mb-8 ">
            <BsSearch className="absolute left-3 top-1/2 -mt-2.5 text-gray-700" />
            <input
              type="text"
              className="focus:outline-none w-full bg-slate-50 text-slate-800 placeholder-slate-400 rounded-md p-8 py-3 pl-10 shadow-md"
              aria-label="Search"
              placeholder="Search..."
              onChange={handleSearch}
              value={searchTerm}
            />
          </form>
          <div id="sidenavExample">
            <h4 className="mb-3 text-lg font-bold tracking-tight">
              Categories
            </h4>
            <ul className="relative">
              {categories.length > 0 ? (
                categories.map(({ id, name }) => (
                  <li key={id} className="relative" id="sidenavEx1">
                    <a
                      href="/admin"
                      className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
                    >
                      <span>{name}</span>
                    </a>
                  </li>
                ))
              ) : (
                <p>No categories found. Try creating one!</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogManagement;
