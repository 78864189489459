import { Link } from "react-router-dom";

//TODO: FIX THIS PART
export const getLocation = (location) => {
  const locationArray = location.pathname.split("/");
  const locationLinks = locationArray
    .filter((part) => isNaN(Number(part)))
    .map((part, index) => {
      const to = locationArray.slice(0, index + 1).join("/");
      return (
        <div key={to}>
          {index !== 0 ? "/" : ""}
          <Link to={to}>{part}</Link>
        </div>
      );
    });
  return locationLinks;
};
