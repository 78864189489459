import { BsSearch } from "react-icons/bs";
import { AiTwotoneEdit, AiFillDelete } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { getLocation } from "../../helpers/getLocation";
import { useEffect, useState } from "react";
import { url } from "../../../utils/url";
import apiService from "../../../api/apiService";
import { useAlert } from "../../../context/AlertContext";

const Users = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const { setAlertMessage } = useAlert();

  const maxRetry = 3;
  let retryCount = 0;
  
  const getUsers = async () => {
      try {
          const response = await apiService.getUsers();
          const data = response.data;
          setUsers(data.results);
          retryCount = 0;
      } catch (error) {
          console.log(error);
          retryCount++;
          if (retryCount <= maxRetry) {
              getUsers();
          }
      }
  };
  
  useEffect(() => {
      getUsers();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUserList = users.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const deleteUser = (id) => {
    apiService
      .deleteUser(id)
      .then(() => {
        setAlertMessage({
          msg: "The user has been successfully deleted.",
          type: "warning",
        });
        getUsers();
      })
      .catch((error) => {
        setAlertMessage({
          msg: `An error occurred while deleting the user. ${error}`,
          type: "error",
        });
        console.error(error);
      });
  };

  return (
    <section className="dashboard__section flex flex-col">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          User Management
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>
      <div className="flex flex-wrap-reverse xl:flex-nowrap gap-9 w-full">
        <div
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
          className="xl:w-9/12 w-full bg-white py-10 px-6 rounded-md ml-auto"
        >
          <table className="w-full border text-sm text-left">
            <thead className="text-xs uppercase bg-[#5ab7ac] text-[#fff]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Full Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Is Active?
                </th>
                <th scope="col" className="px-6 py-3">
                  Is Staff?
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUserList.map(
                ({ id, first_name, last_name, email, is_active, is_staff }) => (
                  <tr
                    key={id}
                    className="text-gray-600 border-b hover:bg-[#5ab7ac30]"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {first_name} {last_name}
                    </th>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {email}
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {is_active ? "Yes" : "No"}
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {is_staff ? "Yes" : "No"}
                    </td>
                    <td className="px-6 py-4 flex gap-5 text-lg">
                      <Link
                        to={url("admin.users.user", { id: [id] })}
                        className="font-medium text-[#5ab7ac] hover:text-[#5ab7ac57] transition ease-in-out duration-500"
                      >
                        <AiTwotoneEdit />
                      </Link>
                      <span
                        onClick={() => deleteUser(id)}
                        href="/"
                        className="cursor-pointer font-medium text-red-700 hover:text-red-500 transition ease-in-out duration-500"
                      >
                        <AiFillDelete />
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>

        <div
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
          className="xl:w-3/12 w-full bg-white py-5 px-6 rounded-md ml-auto"
        >
          <Link to={url("admin.users.adduser")}>
            <button className="btns mb-4 w-full">Add New User</button>
          </Link>
          <h4 className="mb-3 text-lg font-bold tracking-tight">Search</h4>
          <form className="group relative mb-8 ">
            <BsSearch className="absolute left-3 top-1/2 -mt-2.5 text-gray-700" />
            <input
              type="text"
              className="focus:outline-none w-full bg-slate-50 text-slate-800 placeholder-slate-400 rounded-md p-8 py-3 pl-10 shadow-md"
              aria-label="Search"
              placeholder="Search..."
              onChange={handleSearch}
              value={searchTerm}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Users;
