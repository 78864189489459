import { createContext, useContext, useState, useEffect } from "react";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState("test");
  const [alertClass, setAlertClass] = useState("");

  useEffect(() => {
    if (alertMessage) {
      setAlertClass("active");
      const timeout = setTimeout(() => {
        setAlertClass("hide");
      }, 5000);

      const timeout2 = setTimeout(() => {
        setAlertMessage("");
      }, 7300);
      return () => clearTimeout(timeout, timeout2);
    }
  }, [alertMessage]);

  const data = { alertMessage, setAlertMessage, alertClass };

  return <AlertContext.Provider value={data}>{children}</AlertContext.Provider>;
};

export const useAlert = () => useContext(AlertContext);
