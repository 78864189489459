import { ValidationError } from "yup";

export async function handleValidationAndBackendErrors(
  data,
  schema,
  setErrors,
  asyncAction
) {
  try {
    await schema.validate(data, { abortEarly: false });
    setErrors({}); // Clear previous errors
    const response = await asyncAction(data); // This will execute your async backend call, like login or register
    return response; // Return the response for further handling
  } catch (error) {
    if (error instanceof ValidationError) {
      error.inner.forEach((err) => {
        setErrors((prev) => ({
          ...prev,
          [err.path]: err.message,
        }));
      });
      console.log("Val Hata", error);
      // Return a custom error object if a validation error occurs
      return { validationError: true };
    } else {
      throw error; // Other errors
    }
  }
}
