import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const TrLayout = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      i18n.changeLanguage("tr");
    }, 1000);
    return () => clearTimeout(timeout);
  }, [i18n]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default TrLayout;
