import { useLocation, useNavigate } from "react-router-dom";
import { getLocation } from "../../helpers/getLocation";
import { useEffect, useRef, useState } from "react";
import { useAlert } from "../../../context/AlertContext";
import apiService from "../../../api/apiService";
import CategoryModal from "../../components/CategoryModal";
import { AiOutlinePlus } from "react-icons/ai";
import BlogForm from "../../components/BlogForm";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const AddBlog = () => {
  const [image, setImage] = useState();
  const location = useLocation();
  const statusRef = useRef("draft");
  const [imgFilter, setImgFilter] = useState("none");
  const { setAlertMessage } = useAlert();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);

  const enTitleRef = useRef();
  const [enTinyContent, setEnTinyContent] = useState();
  const [enTags, setEnTags] = useState([]);

  const trTitleRef = useRef();
  const [trTinyContent, setTrTinyContent] = useState();
  const [trTags, setTrTags] = useState([]);

  const noTitleRef = useRef();
  const [noTinyContent, setNoTinyContent] = useState();
  const [noTags, setNoTags] = useState([]);

  const navigate = useNavigate();

  const handleTab = (e) => {
    const tabId = e.target.dataset.name;

    if (tabId === "tab-en") {
      setActiveStatus(1);
    } else if (tabId === "tab-tr") {
      setActiveStatus(2);
    } else if (tabId === "tab-no") {
      setActiveStatus(3);
    }
  };

  const addImg = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await apiService.getCategories();
      setCategories(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleClose = () => {
    setShowEditModal(false);
  };
  const handleOpen = () => {
    setShowEditModal(true);
  };
  const handleCreate = (e) => {
    e.preventDefault();

    const categoryName = e.target.elements.categoryName.value;

    apiService
      .createCategory({ name: categoryName })
      .then(() => {
        getCategories();
        setAlertMessage({
          msg: "The category has been successfully added.",
          type: "success",
        });
        handleClose();
      })
      .catch((error) => {
        setAlertMessage({
          msg: `An error occurred while adding the category. ${error}`,
          type: "error",
        });
      });
  };

  const createblog = async (e) => {
    e.preventDefault();

    // TODO: FORM VALIDATION

    // const enTitleRef = useRef();
    // const [enTinyContent, setEnTinyContent] = useState();
    // const [enTags, setEnTags] = useState([]);
  
    // const trTitleRef = useRef();
    // const [trTinyContent, setTrTinyContent] = useState();
    // const [trTags, setTrTags] = useState([]);
  
    // const noTitleRef = useRef();
    // const [noTinyContent, setNoTinyContent] = useState();
    // const [noTags, setNoTags] = useState([]);

    const enTitle = enTitleRef?.current?.value;
    const trTitle = trTitleRef?.current?.value;
    const noTitle = noTitleRef?.current?.value;

    const status = statusRef?.current?.value;

    const formData = new FormData();
    formData.append("title_en", enTitle);
    formData.append("title_tr", trTitle);
    formData.append("title_no", noTitle);
    formData.append("content_en", enTinyContent);
    formData.append("content_tr", trTinyContent);
    formData.append("content_no", noTinyContent);
    formData.append("tags_en", enTags);
    formData.append("tags_tr", trTags);
    formData.append("tags_no", noTags);
    formData.append("status", status);
    formData.append("category_id", parseInt(selectedCategory));
    formData.append("image_filter", imgFilter !== "none" ? imgFilter : "none");

    if (image) {
      formData.append("blog_image", image);
    }

    try {
      await apiService.createBlog(formData);
      setAlertMessage({
        msg: "The blog has been successfully added.",
        type: "success",
      });
      navigate("/admin/blog-management");
    } catch (error) {
      setAlertMessage({
        msg: `An error occurred while adding the category. ${error}`,
        type: "error",
      });
    }
  };

  return (
    <section className="dashboard__section">
      <div className="flex flex-wrap justify-between items-center py-3">
        <h2 className="mb-2 sm:text-2xl text-lg font-bold tracking-tight">
          Add a new blog
        </h2>
        <span className="flex flex-wrap sm:text-lg text-sm">
          {getLocation(location)}
        </span>
      </div>

      <div
        style={{
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
        className="flex flex-col  w-full px-6 rounded-lg bg-white"
      >
        {/*Tabs navigation*/}
        <ul className="mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0">
          <li className="flex-grow basis-0 text-center">
            <span
              className="cursor-pointer my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              {...(activeStatus === 1 && { "data-te-nav-active": true })}
              data-name="tab-en"
              onClick={handleTab}
            >
              English
            </span>
          </li>
          <li className="flex-grow basis-0 text-center">
            <span
              className="cursor-pointer focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-name="tab-tr"
              {...(activeStatus === 2 && { "data-te-nav-active": true })}
              onClick={handleTab}
            >
              Turkish
            </span>
          </li>
          <li className="flex-grow basis-0 text-center">
            <span
              className="cursor-pointer my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-name="tab-no"
              {...(activeStatus === 3 && { "data-te-nav-active": true })}
              onClick={handleTab}
            >
              Norwegian
            </span>
          </li>
        </ul>

        {/*Tabs content*/}
        <div className="mb-6">
        <form onSubmit={createblog}>
          <div
            className={`${
              activeStatus !== 1 && "hidden"
            } opacity-100 transition-opacity duration-150 ease-linear`}
          >
            {image && (
              <img
                className={`${
                  imgFilter && imgFilter
                } h-[450px] select-none w-full object-cover rounded`}
                src={URL.createObjectURL(image)}
                alt="title"
              />
            )}

            <div className="mt-4">
              <dl className="text-xs font-medium flex items-center row-start-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
                <h6 className="text-sm ml-auto">
                  {new Date().getDate()}/{new Date().getMonth() + 1}/
                  {new Date().getFullYear()}
                  {/* TODO: Tarih ayari yap */}
                </h6>
              </dl>
            </div>

            <div className="flex justify-center">
              <div className="mt-5 w-full">
                <label
                  htmlFor="image"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Choose cover image
                </label>
                <input
                  className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-[#eee]  bg-clip-padding px-3 py-[0.32rem] text-base font-normal bg-white text-slate-500 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                  type="file"
                  id="image"
                  name="blog_image"
                  onChange={addImg}
                  multiple={false}
                  accept="image/*"
                />
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <label
                htmlFor="category"
                className="mb-2 inline-block text-neutral-70"
              >
                Category
              </label>
              <div className="flex items-center">
                <select
                  defaultValue=""
                  className="border-solid border-2 border-gray-300 focus:outline-none w-full bg-white placeholder-gray-400 rounded p-3 text-sm"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option disabled value="">
                    Select a category
                  </option>
                  {categories?.map(({ id, name }) => (
                    <option
                      key={id}
                      className="text-gray-900"
                      value={id}
                      id="category"
                    >
                      {name}
                    </option>
                  ))}
                </select>
                <span
                  role="button"
                  tabIndex="0"
                  className="focus:outline-none rounded-sm text-sm p-3 text-center text-[#fff] bg-[#5AB7AC] hover:bg-[#5AB7ACD6] inline-flex items-center ml-2"
                  onClick={handleOpen}
                >
                  <AiOutlinePlus className="mr-1" />
                  Add
                </span>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <div className="w-full">
                <label
                  htmlFor="imgFilter"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Image Filter
                </label>
                <select
                  onChange={(e) => setImgFilter(e.target.value)}
                  id="imgFilter"
                  className="border-solid border-2 border-gray-300 focus:outline-none w-full bg-white placeholder-gray-400 rounded p-3 text-sm"
                >
                  <option className="text-gray-900" value="none" name="none">
                    None
                  </option>
                  <option className="text-gray-900" value="blur" name="blur">
                    Blur
                  </option>
                  <option
                    className="text-gray-900"
                    value="brightness"
                    name="brightness"
                  >
                    Brightness
                  </option>
                  <option
                    className="text-gray-900"
                    value="contrast"
                    name="contrast"
                  >
                    Contrast
                  </option>
                  <option
                    className="text-gray-900"
                    value="grayscale"
                    name="grayscale"
                  >
                    Grayscale
                  </option>
                  <option
                    className="text-gray-900"
                    value="huerotate"
                    name="huerotate"
                  >
                    Huerotate
                  </option>
                  <option
                    className="text-gray-900"
                    value="invert"
                    name="invert"
                  >
                    Invert
                  </option>
                  <option
                    className="text-gray-900"
                    value="opacity"
                    name="opacity"
                  >
                    Opacity
                  </option>
                  <option
                    className="text-gray-900"
                    value="saturate"
                    name="saturate"
                  >
                    Saturate
                  </option>
                  <option className="text-gray-900" value="sepia" name="sepia">
                    Sepia
                  </option>
                </select>
              </div>
            </div>

            <BlogForm
              titleRef={enTitleRef}
              setTinyContent={setEnTinyContent}
              setAlertMessage={setAlertMessage}
              tags={enTags}
              setTags={setEnTags}
              tinyId={"enTiny"}
            />

            <div className="flex mt-3">
              <button
                type="button"
                className="ml-auto bg-gray-800 text-white rounded-r-md py-2 border-l border-gray-200 hover:bg-[#5ab7acd7] hover:text-white px-3"
                data-name="tab-tr"
                onClick={handleTab}
              >
                <div data-name="tab-tr" className="flex flex-row align-middle">
                  <span data-name="tab-tr" className="ml-1">
                    Next
                  </span>
                  <FaArrowRight
                    data-name="tab-tr"
                    className="w-5 ml-2 my-auto"
                  />
                </div>
              </button>
            </div>
          </div>
          <div
            className={`${
              activeStatus !== 2 && "hidden"
            } opacity-100 transition-opacity duration-150 ease-linear`}
          >
            <BlogForm
              titleRef={trTitleRef}
              setTinyContent={setTrTinyContent}
              setAlertMessage={setAlertMessage}
              tags={trTags}
              setTags={setTrTags}
              tinyId={"trTiny"}
            />
            <div className="flex mt-3">
              <button
                type="button"
                className="bg-gray-800 text-white rounded-l-md border-r border-gray-100 py-2 hover:bg-[#5ab7acd7] hover:text-white px-3"
                data-name="tab-en"
                onClick={handleTab}
              >
                <div data-name="tab-en" className="flex flex-row align-middle">
                  <FaArrowLeft
                    data-name="tab-en"
                    className="w-5 mr-2 my-auto"
                  />
                  <span data-name="tab-en" className="mr-1">
                    Prev
                  </span>
                </div>
              </button>
              <button
                type="button"
                className="ml-auto bg-gray-800 text-white rounded-r-md py-2 border-l border-gray-200 hover:bg-[#5ab7acd7] hover:text-white px-3"
                data-name="tab-no"
                onClick={handleTab}
              >
                <div data-name="tab-no" className="flex flex-row align-middle">
                  <span data-name="tab-no" className="ml-1">
                    Next
                  </span>
                  <FaArrowRight
                    data-name="tab-no"
                    className="w-5 ml-2 my-auto"
                  />
                </div>
              </button>
            </div>
          </div>
          <div
            className={`${
              activeStatus !== 3 && "hidden"
            } opacity-100 transition-opacity duration-150 ease-linear`}
          >
            <BlogForm
              titleRef={noTitleRef}
              setTinyContent={setNoTinyContent}
              setAlertMessage={setAlertMessage}
              tags={noTags}
              setTags={setNoTags}
              tinyId={"noTiny"}
            />

            <div className="flex justify-center mt-4">
              <div className="w-full">
                <label
                  htmlFor="status"
                  className="mb-2 inline-block text-neutral-70"
                >
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  className="border-solid border-2 border-gray-300 focus:outline-none w-full bg-white placeholder-gray-400 rounded p-3 text-sm"
                  ref={statusRef}
                >
                  <option className="text-gray-900" value="draft" name="draft">
                    Draft
                  </option>
                  <option
                    className="text-gray-900"
                    value="published"
                    name="published"
                  >
                    Published
                  </option>
                </select>
              </div>
            </div>
            <div className="flex mt-3">
              <button
                type="button"
                className="bg-gray-800 text-white rounded-l-md border-r border-gray-100 py-2 hover:bg-[#5ab7acd7] hover:text-white px-3"
                data-name="tab-tr"
                onClick={handleTab}
              >
                <div data-name="tab-tr" className="flex flex-row align-middle">
                  <FaArrowLeft
                    data-name="tab-tr"
                    className="w-5 mr-2 my-auto"
                  />
                  <span data-name="tab-tr" className="mr-1 ">
                    Prev
                  </span>
                </div>
              </button>
              <button
                type="submit"
                className="ml-auto focus:outline-none rounded-sm text-sm px-3 py-2 text-center text-[#fff] bg-[#5ab7ac] hover:bg-[#5ab7acd7]"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        </div>

        {showEditModal && (
          <CategoryModal
            mode="create"
            onClose={handleClose}
            onCreate={handleCreate}
          />
        )}
      </div>
    </section>
  );
};

export default AddBlog;
