import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function AdminPrivateRouter({ children }) {
  const { user } = useAuth();
  const location = useLocation();
  if (!((user?.is_staff ?? false) || (user?.is_admin ?? false))) {
    return (
      //TODO: Link Name
      <Navigate
        to={"/"}
        replace
        state={{
          return_url: location.pathname,
        }}
      />
    );
  }
  return children;
}
