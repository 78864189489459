const Pagination = ({ count, setPage, currentPage }) => {

  // Sayfalar için bir dizi oluştur
  const pages = Array.from({ length: count }, (_, i) => i + 1);

  return (
    <nav aria-label="Page navigation example">
      <ul className="list-style-none flex">
        <li>
          <button
            onClick={() => currentPage >= 1 && setPage(currentPage - 1)}
            className={`relative block rounded bg-transparent px-5 py-3 text-lg transition-all duration-300 ${
              currentPage > 0
                ? "text-white cursor-pointer"
                : "text-neutral-500 pointer-events-none"
            }`}
          >
            Önceki
          </button>
        </li>
        {pages.map((page) => (
          <li key={page}>
            <button
              onClick={() => setPage(page - 1)}
              className={`relative block rounded bg-transparent px-5 py-3 text-lg transition-all duration-300 ${
                currentPage + 1  === page
                  ? "text-white cursor-pointer"
                  : "text-neutral-500 cursor-pointer"
              }`}
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={() => currentPage < count && setPage(currentPage + 1)}
            className={`relative block rounded bg-transparent px-5 py-3 text-lg transition-all duration-300 ${
              currentPage < count - 1
                ? "text-white cursor-pointer"
                : "text-neutral-500 pointer-events-none"
            }`}
          >
            Sonraki
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
