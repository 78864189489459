import { useTranslation } from "react-i18next";

const DevModal = ({ setIsModalOpen }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h1>{t("Dev Modal")}</h1>
          <button className="close-modal" onClick={() => setIsModalOpen(false)}>
            &times;
          </button>
        </div>
        <div className="modal-content">
          <h2>{t("Dev Modal Intro")}</h2>
          <p>{t("Dev Modal P1")}</p>
          <p>{t("Dev Modal P2")}</p>
          <p>{t("Dev Modal P3")}</p>
          <p>{t("Dev Modal P4")}</p>
          <span>{t("Dev Modal Sincerely")}</span>
          <span>Sinan Sarikaya</span>
        </div>
      </div>
    </div>
  );
};

export default DevModal;
