import { useRoutes } from "react-router-dom";
import Alert from "../components/Alert";
import routes from "../router/routes";

const AppRouter = () => {
  return (
    <>
      <Alert />
      {useRoutes(routes)}
    </>
  );
};

export default AppRouter;
